import React, { useRef } from "react";
import GoogleMapReact from "google-map-react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"; // React Router for navigation

const defaultProps = {
  center: { lat: 15, lng: 70 }, // Default location if no trips
  zoom: 1,
};

const Marker = ({ trip, goToMapView, transport }) => {  
  return (
    <div
      style={styles.marker}
      onClick={() => goToMapView(trip)} // 🔥 Click to navigate
    >
      {/* Image for transport */}
      {transport && (
        <img
          key={trip.name}
          style={{
            width: 40,
            height: 40,
            objectFit: "contain",
            transform: "translate(-50%, -100%)",
            position: "absolute",
            left: "50%",
            top: "-20px",
            zIndex: 2
          }}
          src={transport.image_url} // Load correct transport icon
          alt={trip.transport}
        />
      )}
      {/* Trip Name */}
      <div
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          padding: "5px 10px",
          borderRadius: "8px",
          position: "absolute",
          left: "50%",
          bottom: "-5px",
          transform: "translateX(-50%)",
          fontFamily: "Arial, sans-serif",
          textOverflow: "-moz-initial",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          lineHeight: "1.2",
          maxHeight: "2.8em",
          zIndex: 3,
          boxSizing: "border-box",
          minWidth: "100px",
          maxWidth: "300px",
          width: "fit-content"
        }}
      >
        {trip.name}
      </div>
    </div>
  );
};


export default function MainPageMap({ center }) {
  const history = useHistory();
  const mapRef = useRef();

  const trips = useSelector((state) => state.trips);
  const transportations = useSelector((state) => state.transportations);

  const goToMapView = (trip) => {
    history.push(`/map?tripId=${trip.id}`);
  };

  const GOOGLE_API_KEY = "AIzaSyA7QJursebL1QtVEKAjr2q-AGfwvBXLmjk";

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={center || defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{
          streetViewControl: false,
          scaleControl: false,
          fullscreenControl: false,
          gestureHandling: "greedy",
          mapTypeControl: true,
          mapTypeId: "hybrid",
          labels: false,
          mapTypeControlOptions: false,
          zoomControl: false,
          clickableIcons: false,
        }}
        loadingStrategy="lazyOnload"
      >
        {trips.map((trip) => {
          const transport = transportations.find((t) => t.name === trip.transport);
          return (
            <Marker
              key={trip.id}
              trip={trip}
              lat={trip.initial_lat}
              lng={trip.initial_lng}
              goToMapView={goToMapView}
              transport={transport}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

// Styles
const styles = {
  mapContainer: {
    width: "100%", // Full width
    height: "300px", // Fixed height
    overflow: "hidden",
    position: "relative",
  },
  marker: {
    position: "relative",
    textAlign: "center",
    cursor: "pointer"
  }
};