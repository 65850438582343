const storedClientId = localStorage.getItem("clientId") || crypto.randomUUID();
localStorage.setItem("clientId", storedClientId); // Ensure it's always saved

const initialState = storedClientId; // Use stored value if available

export default function clientIdReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CLIENT_ID":
      localStorage.setItem("clientId", action.clientId); // Save updates to localStorage
      return action.clientId;
    default:
      return state;
  }
}