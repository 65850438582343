import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; // React Router v5 navigation
import MainPageMap from "./MainPageMap";
import { setAuthenticated } from "../redux/actions/isAuthenticated";
import { setTrips } from "../redux/actions/trips";
import { setTransportations } from "../redux/actions/transportations";
import { useSelector, useDispatch } from "react-redux";
import { apiFetch } from "../utils/api";

export default function MainPage() {
  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.clientId); // Correct Redux access
  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [mapCenter, setMapCenter] = useState(null);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showLoginPanel, setShowLoginPanel] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // ✅ Check session on load
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await apiFetch("https://hawkon.eu/api/checkSession", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clientId }),
        });

        const data = await response.json();

        if (data.authenticated) {
          dispatch(setAuthenticated(true));
          if (data.trips) {
            dispatch(setTrips(data.trips));
            console.log('Trips:', data.trips);
            // Set map center to first trip if available and coordinates are valid
            if (data.trips.length > 0 && 
                data.trips[0].initial_lat && 
                data.trips[0].initial_lng) {
              const newCenter = {
                lat: parseFloat(data.trips[0].initial_lat),
                lng: parseFloat(data.trips[0].initial_lng)
              };
              setMapCenter(newCenter);
            }
          }
          if (data.transportations) {
            dispatch(setTransportations(data.transportations));
          }
        } else {
          dispatch(setAuthenticated(false));
        }
      } catch (error) {
        console.error("Error checking session:", error);
      }
    };

    checkSession();
  }, [clientId, dispatch]);

  // ✅ Fetch transportation options
  useEffect(() => {
    const fetchTransportations = async () => {
      if (!isAuthenticated) return; // Skip if not authenticated
      
      try {
        const res = await apiFetch(`https://hawkon.eu/api/admin/transportations?clientId=${clientId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });
        const data = await res.json();
        console.log("Transportations:", data);
        dispatch(setTransportations(data));
      } catch (err) {
        console.error("Failed to fetch transportations", err);
      }
    };
    fetchTransportations();
  }, [clientId, dispatch, isAuthenticated]); // Added isAuthenticated to dependencies

  // Handle login form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiFetch("https://hawkon.eu/api/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, password, clientId }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setAuthenticated(true));
        if (data.trips) {
          console.log('Trips:', data.trips);
          dispatch(setTrips(data.trips));
          // Set map center to first trip if available and coordinates are valid
          if (data.trips.length > 0 && 
              data.trips[0].initial_lat && 
              data.trips[0].initial_lng) {
            const newCenter = {
              lat: parseFloat(data.trips[0].initial_lat),
              lng: parseFloat(data.trips[0].initial_lng)
            };
            setMapCenter(newCenter);
          }
        }
        if (data.transportations) {
          dispatch(setTransportations(data.transportations));
        }
        if (name) {
          localStorage.setItem("name", name);
        }
        setError(null);
        setShowLoginPanel(false);
      } else {
        setError(data.error || "Invalid credentials");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Something went wrong. Try again.");
    }
  };

  return (
    <div style={styles.pageContainer}>
      {/* Welcome Message in the Top Left */}
      <div style={styles.welcomeContainer}>
        {isAuthenticated && !isMobile && (
          <div style={styles.welcomeMessage}>
            Welcome - {name} - {clientId?.slice(-5)}
          </div>
        )}
      </div>

      {/* Right: Buttons */}
      <div style={styles.buttonGroup}>
        {isAuthenticated ? (
          <>
            {/* Trip Planner Button */}
            <button
              style={styles.adminButton}
              onClick={() => history.push("/trip-planner")}
            >
              Ny reise
            </button>

            {/* Admin Button */}
            <button
              style={styles.adminButton}
              onClick={() => history.push("/admin")}
            >
              Admin
            </button>


            {/* Logout Button */}
            <button
              style={styles.logoutButton}
              onClick={() => {
                const newClientId = crypto.randomUUID();

                dispatch({ type: "SET_CLIENT_ID", clientId: newClientId });
                localStorage.setItem("clientId", newClientId);
                localStorage.setItem("name", "");

                dispatch(setAuthenticated(false));
              }}
            >
              Logout
            </button>
          </>
        ) : (
          <button
            style={styles.loginButton}
            onClick={() => setShowLoginPanel(true)}
          >
            Login
          </button>
        )}
      </div>

      <h1 style={styles.header}>Vår lille reise-bilde-dagbok</h1>

      {!isAuthenticated && (
        <p style={{
          fontSize: "0.9em",
          color: "#666",
          textAlign: "center",
          margin: "10px 0"
        }}>
          Logg inn for å se hvor vi har vært
        </p>
      )}

      <MainPageMap center={mapCenter} />

      {/* Login Panel Overlay */}
      {showLoginPanel && (
        <div style={styles.overlay}>
          <div style={styles.loginPanel}>
            <button 
              style={styles.closeButton}
              onClick={() => setShowLoginPanel(false)}
            >
              ✕
            </button>
            <h2>Login</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={styles.input}
              />
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
              />
              <button type="submit" style={styles.submitButton}>Login</button>
              {error && <p style={styles.error}>{error}</p>}
            </form>
          </div>
        </div>
      )}
    </div >
  );
}

// Styles
const styles = {
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
    paddingBottom: "20px",
    position: "relative", // Needed for positioning welcome message
  },
  welcomeContainer: {
    position: "absolute",
    top: "10px",
    left: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    zIndex: 1000,
  },
  welcomeMessage: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#007bff",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    width: "100%",
  },
  input: {
    padding: "12px",
    fontSize: "16px",
    width: "100%",
    maxWidth: "300px",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },
  submitButton: {
    padding: "12px 24px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#28a745",
    color: "#fff",
    width: "100%",
    maxWidth: "300px",
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginTop: "10px",
  },
  buttonGroup: {
    position: "absolute",
    top: "10px",
    right: "20px",
    display: "flex",
    gap: "10px",
    zIndex: 1000,
  },
  adminButton: {
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  logoutButton: {
    padding: "8px 16px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  loginButton: {
    padding: "8px 16px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  loginPanel: {
    backgroundColor: "white",
    padding: "30px",
    borderRadius: "12px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative",
    width: "90%",
    maxWidth: "400px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    color: "#666",
    padding: "5px",
  },
  header: {
    marginTop: "60px",
    textAlign: "center",
    width: "100%"
  },
};