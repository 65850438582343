import React, { useState } from 'react';

const TransportDropdown = ({ transportations, selectedTransportName, setSelectedTransportName, editingIndex, selectedPlaces, setSelectedPlaces }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedTransport = transportations?.find(t => t.name === selectedTransportName);

  const handleSelect = (transportName) => {
    setSelectedTransportName(transportName);
    
    // Update the transport attribute of the currently selected place
    if (editingIndex !== null && selectedPlaces) {
      setSelectedPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[editingIndex] = {
          ...updatedPlaces[editingIndex],
          transport: transportName
        };
        return updatedPlaces;
      });
    }
    
    setIsOpen(false);
  };

  return (
    <div style={{ position: 'relative', width: '250px' }}>
      <label style={{ marginBottom: '5px', display: 'block' }}>How did you get here?</label>

      {/* Selected Option */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          cursor: 'pointer',
          backgroundColor: '#f9f9f9'
        }}
      >
        {selectedTransport ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img
              src={selectedTransport.image_url}
              alt={selectedTransport.name}
              style={{ width: '30px', height: '30px', objectFit: 'contain' }}
            />
            <span style={{
              color: 'black'
            }}>{selectedTransport.name}</span>
          </div>
        ) : (
          <span style={{
            color: 'black'
          }}>Select transportation</span>
        )}

        <span style={{
              color: 'black'
            }}>▼</span>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '8px',
            maxHeight: '200px',
            overflowY: 'auto',
            zIndex: 1000
          }}
        >
          {transportations.map((t) => (
            <div
              key={t.name}
              onClick={() => handleSelect(t.name)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee',
                backgroundColor: selectedTransportName === t.name ? '#f0f0f0' : 'white'
              }}
            >
              <img
                src={t.image_url}
                alt={t.name}
                style={{ width: '30px', height: '30px', objectFit: 'contain' }}
              />
              <span style={{
                color: 'black'
              }} >{t.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TransportDropdown;