import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { useLocation, useHistory } from "react-router-dom";
import { getBoundsOfTripFromHomeAndSteps, fetchRoute, getBoundsBetweenPoints } from '../utils/mapHelper';
import { apiFetch } from "../utils/api";
import StopMarker from './StopMarker';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CustomMarker = ({ children }) => (
  <div style={{ position: "relative", transform: "translate(-50%, -50%)" }}>
    {children}
  </div>
);

const calculateHeading = (start, end) => {
  const toRad = (degree) => (degree * Math.PI) / 180;
  const toDeg = (radian) => (radian * 180) / Math.PI;
  const lat1 = toRad(start.lat);
  const lat2 = toRad(end.lat);
  const dLon = toRad(end.lng - start.lng);
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  let bearing = Math.atan2(y, x);
  bearing = toDeg(bearing);
  return (bearing + 360) % 360;
};

export default function MapPage() {
  const query = useQuery();
  const tripId = query.get("tripId");
  const history = useHistory();

  const mapRef = useRef();
  const previousTransportPosition = useRef(null);

  const clientId = useSelector((state) => state.clientId);

  const [tripData, setTripData] = useState(null);
  const [steps, setSteps] = useState([]);
  const [transportations, setTransportations] = useState([]);
  const [transportPosition, setTransportPosition] = useState(null);
  const [currentMediaDisplay, setCurrentMediaDisplay] = useState([]);
  const [continueHandler, setContinueHandler] = useState(null);
  const [isReturningHome, setIsReturningHome] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  const [googleMapInstance, setGoogleMapInstance] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [hasLoopedOnce, setHasLoopedOnce] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const skipAnimationRef = useRef(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);

  const GOOGLE_API_KEY = "AIzaSyA7QJursebL1QtVEKAjr2q-AGfwvBXLmjk";

  // Add new state for media groups
  const [mediaGroups, setMediaGroups] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const videoRefs = useRef({});
  const [hoveredThumb, setHoveredThumb] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await apiFetch("https://hawkon.eu/api/checkSession", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ clientId }),
        });
        const data = await response.json();
        if (data.authenticated) {
          setIsAdmin(data.isAdmin);
        }
      } catch (error) {
        console.error("❌ Error checking session:", error);
      }
    };
    checkSession();
  }, [clientId]);

  const fetchTransportations = useCallback(async () => {
    try {
      const res = await apiFetch(`https://hawkon.eu/api/admin/transportations?clientId=${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      setTransportations(data);
    } catch (err) {
      console.error("❌ Failed to fetch transportations", err);
    }
  }, [clientId, setTransportations]);

  useEffect(() => {
    if (!tripId) {
      console.log("❌ No tripId provided, redirecting to home");
      history.push('/');
      return;
    }

    const fetchTripPath = async () => {
      try {
        const tripRes = await apiFetch(`https://hawkon.eu/api/getTripPath?tripId=${tripId}&clientId=${clientId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const tripData = await tripRes.json();

        if (!tripData.success) return;

        setTripData(tripData.trip);
        setSteps(tripData.pathSteps);

      } catch (error) {
        console.error("❌ Error fetching trip and pathSteps:", error);
      }
    };

    fetchTripPath();
    fetchTransportations();
  }, [tripId, clientId, fetchTransportations, history]);


  const findLastValidCoordStep = useCallback(
    (index) => {
      // 1. Look backward through the steps array for valid lat/lng
      for (let i = index; i >= 0; i--) {
        const s = steps[i];

        if (
          s &&
          s.action_type !== "pan" &&
          s.lat !== null &&
          s.lng !== null &&
          !isNaN(parseFloat(s.lat)) &&
          !isNaN(parseFloat(s.lng))
        ) {
          return {
            lat: parseFloat(s.lat),
            lng: parseFloat(s.lng),
          };
        }
      }

      // 2. Fallback to tripData home_lat/home_lng
      const homeLat = parseFloat(tripData?.home_lat);
      const homeLng = parseFloat(tripData?.home_lng);

      if (!isNaN(homeLat) && !isNaN(homeLng)) {
        return {
          lat: homeLat,
          lng: homeLng,
        };
      }

      console.warn("❌ No valid previous step found. Falling back to (0, 0)");
      return { lat: 0, lng: 0 };
    },
    [steps, tripData?.home_lat, tripData?.home_lng] // ✅ dependencies
  );

  const getTransportIcon = useCallback(
    (transportName) => {
      if (!transportName || !transportations.length) return null;

      const match = transportations.find(
        (t) => t.name.toLowerCase() === transportName.toLowerCase()
      );

      return match
        ? match.image_url
        : "https://travel-journal.ams3.cdn.digitaloceanspaces.com/transportation/default-transport.png";
    },
    [transportations] // ✅ dependency on transportations array
  );

  const animateTransport = useCallback(async (from, to, transportImageUrl, options = {}) => {
    const { reverse = false } = options;

    // 1️⃣ Validate coordinates
    const startLat = parseFloat(from.lat);
    const startLng = parseFloat(from.lng);
    const endLat = parseFloat(to.lat);
    const endLng = parseFloat(to.lng);

    if ([startLat, startLng, endLat, endLng].some(coord => isNaN(coord))) {
      console.error("❌ Invalid coords in animateTransport", { from, to });
      return;
    }

    const start = { lat: reverse ? endLat : startLat, lng: reverse ? endLng : startLng };
    const end = { lat: reverse ? startLat : endLat, lng: reverse ? startLng : endLng };

    // 2️⃣ Fetch route (or fallback to straight line)
    const isRouteTransport = !(
      transportImageUrl?.toLowerCase().includes("boat") ||
      transportImageUrl?.toLowerCase().includes("ferry") ||
      transportImageUrl?.toLowerCase().includes("airplane") ||
      transportImageUrl?.toLowerCase().includes("default-transport")
    );

    setIsAnimating(true);
    skipAnimationRef.current = false;

    const routeCoords = isRouteTransport
      ? await fetchRoute(start, end, clientId)
      : null;

    // Ensure we have at least start and end points
    const path = routeCoords && routeCoords.length > 1
      ? routeCoords.filter(point => point && typeof point.lat === 'number' && typeof point.lng === 'number')
      : [start, end];

    // If path is empty after filtering, fallback to direct line
    if (path.length < 2) {
      path[0] = start;
      path[1] = end;
    }

    // 3️⃣ Animate over path with consistent timing
    const ANIMATION_DURATION = path.length > 2 ? 4000 : 2000; // 4 seconds for routes, 2 seconds for direct lines
    const startTime = performance.now();

    return new Promise((resolve) => {
      const animate = (currentTime) => {
        if (skipAnimationRef.current) {
          // End of animation
          setTransportPosition({
            lat: end.lat,
            lng: end.lng,
            transportImageUrl
          });

          previousTransportPosition.current = end;
          setIsAnimating(false);

          resolve();
          return;
        }

        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / ANIMATION_DURATION, 1);

        if (progress >= 1) {
          // End of animation
          setTransportPosition({
            lat: end.lat,
            lng: end.lng,
            transportImageUrl
          });

          previousTransportPosition.current = end;
          setIsAnimating(false);

          resolve();
          return;
        }

        let currentPoint;

        if (path.length === 2) {
          // ➡️ Straight line between start and end
          currentPoint = {
            lat: start.lat + (end.lat - start.lat) * progress,
            lng: start.lng + (end.lng - start.lng) * progress
          };
        } else {
          // ➡️ Follow route (interpolated)
          const indexFloat = progress * (path.length - 1);
          const index = Math.max(0, Math.floor(indexFloat));
          const nextIndex = Math.min(index + 1, path.length - 1);
          const segmentProgress = indexFloat - index;

          // Ensure we have valid points to interpolate between
          const pointA = path[index];
          const pointB = path[nextIndex];

          if (!pointA || !pointB) {
            console.error("❌ Invalid points in path:", { pointA, pointB, index, nextIndex });
            // Fallback to direct line if points are invalid
            currentPoint = {
              lat: start.lat + (end.lat - start.lat) * progress,
              lng: start.lng + (end.lng - start.lng) * progress
            };
          } else {
            currentPoint = {
              lat: pointA.lat + (pointB.lat - pointA.lat) * segmentProgress,
              lng: pointA.lng + (pointB.lng - pointA.lng) * segmentProgress
            };
          }
        }

        // 🚀 Move marker
        setTransportPosition({
          lat: currentPoint.lat,
          lng: currentPoint.lng,
          transportImageUrl
        });

        // 🚀 Move map camera
        googleMapInstance.setCenter(currentPoint);

        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    });
  }, [googleMapInstance, clientId]);

  const showMediaForStop = useCallback(async (step) => {
    const galleryName = step.gallery_name;
    const images = step.images || [];

    console.log("🖼️ showMediaForStop called with step:", step);
    console.log(`📂 Gallery Name: ${galleryName}`);
    console.log(`🖼️ Images:`, images);

    if (!galleryName) {
      console.warn("⚠️ No galleryName provided, aborting showMediaForStop.");
      return;
    }

    if (images.length === 0) {
      console.warn("⚠️ No images provided in this step, aborting showMediaForStop.");
      return;
    }

    // ✅ Images are already presigned from backend, use them directly
    console.log("🖼️ Displaying images...");
    setCurrentMediaDisplay(images);

    // ✅ Wait for user input to continue
    console.log("⏸️ Waiting for user to press [Space] to continue...");

    await new Promise((resolve) => {
      const continueFn = () => {
        console.log("➡️ User pressed [Space], continuing...");
        cleanup();
        resolve();
      };

      const keyHandler = (e) => {
        if (e.code === "Space") continueFn();
      };

      const cleanup = () => {
        console.log("🧹 Cleaning up media display and event listeners.");
        document.removeEventListener("keydown", keyHandler);
        setCurrentMediaDisplay([]);
        setContinueHandler(null);
      };

      document.addEventListener("keydown", keyHandler);
      setContinueHandler(() => continueFn);
    });

    console.log("✅ Exiting showMediaForStop");
  }, []);

  const advanceStep = useCallback(async () => {
    if (isReturningHome) {
      console.log("↩️ Already returning home, skipping advanceStep");
      return;
    }

    const step = steps[currentStepIndex];

    if (!step) {
      setIsReturningHome(true);
      setIsReturningHome(false);

      setHasLoopedOnce(true); // ✅ Track we completed a full round
      setCurrentStepIndex(0);
      return;
    }

    console.log(`➡️ Executing step ${currentStepIndex + 1}/${steps.length}:`, step);

    // Skip first pan step entirely and execute next step
    if (currentStepIndex === 0 && step.action_type === "pan") {
      // Get current map state
      const currentZoom = googleMapInstance.getZoom();
      const currentCenter = googleMapInstance.getCenter();
      const targetZoom = step.zoom_lvl === -1 ? 9 : parseInt(step.zoom_lvl, 10);
      const targetLat = parseFloat(step.lat);
      const targetLng = parseFloat(step.lng);

      // Check if current map state matches the pan step's requirements
      const zoomMatches = currentZoom === targetZoom;
      const centerMatches = Math.abs(currentCenter.lat() - targetLat) < 0.0001 &&
        Math.abs(currentCenter.lng() - targetLng) < 0.0001;

      if (zoomMatches && centerMatches) {
        console.log("🗺️ Skipping first pan step - map already at correct position and zoom");
        setCurrentStepIndex(prev => prev + 1);
        // Execute the next step immediately
        const nextStep = steps[currentStepIndex + 1];
        if (nextStep) {
          console.log(`➡️ Executing next step immediately:`, nextStep);
          switch (nextStep.action_type) {
            case "animation": {
              const fromCoords = findLastValidCoordStep(currentStepIndex);
              const from = {
                ...fromCoords,
                new_transport: nextStep.new_transport
              };
              const to = {
                lat: parseFloat(nextStep.lat),
                lng: parseFloat(nextStep.lng)
              };
              const transportImage = getTransportIcon(nextStep.new_transport);
              await animateTransport(from, to, transportImage);
              break;
            }
            case "zoom": {
              googleMapInstance.setZoom(parseInt(nextStep.zoom_lvl, 10));
              break;
            }
            case "show_images": {
              await showMediaForStop(nextStep);
              break;
            }
            default: {
              console.warn("❓ Unknown step type:", nextStep.action_type);
            }
          }
          setCurrentStepIndex(prev => prev + 1);
        }
        return;
      } else {
        console.log("🗺️ First pan step needed - adjusting map position and zoom");
      }
    }

    switch (step.action_type) {
      case "animation": {
        console.log("🛫 Animation step", step);

        // 🧐 Find last valid step *before* currentStepIndex
        const fromCoords = findLastValidCoordStep(currentStepIndex - 1);

        const from = {
          ...fromCoords,
          new_transport: step.new_transport
        };
        const to = {
          lat: parseFloat(step.lat),
          lng: parseFloat(step.lng)
        };

        // Get transport image from the step's new_transport
        console.log("🚗 Transport type from step:", step.new_transport);
        console.log("🚗 Available transportations:", transportations);

        const transportImage = getTransportIcon(step.new_transport);
        console.log("🚗 Selected transport image:", transportImage);

        console.log("🔄 Animating from:", from, "to:", to, "with transport:", step.new_transport);

        await animateTransport(from, to, transportImage);
        break;
      }

      case "pan": {
        console.log("🗺️ Pan step", step);

        const coord = {
          lat: parseFloat(step.lat),
          lng: parseFloat(step.lng)
        };

        googleMapInstance.panTo(coord);
        if (step.zoom_lvl === -1) {
          googleMapInstance.setZoom(Math.max(9, googleMapInstance.getZoom() + 1));
        } else {
          googleMapInstance.setZoom(parseInt(step.zoom_lvl, 10));
        }

        // Don't change transport position during pan steps
        // The transport should stay at its current position
        break;
      }

      case "zoom": {
        console.log("🔍 Zoom step");

        googleMapInstance.setZoom(parseInt(step.zoom_lvl, 10));
        break;
      }

      case "show_images": {
        console.log("🖼️ Show images step");

        await showMediaForStop(step);
        break;
      }

      default: {
        console.warn("❓ Unknown step type:", step.action_type);
      }
    }

    setCurrentStepIndex(prev => prev + 1);
  }, [currentStepIndex, steps, googleMapInstance, isReturningHome, transportations, animateTransport, findLastValidCoordStep, getTransportIcon, showMediaForStop]);

  useEffect(() => {
    if (!tripData || steps.length === 0 || transportations.length === 0 || !googleMapInstance) return;

    console.log("Data ready", tripData, steps, transportations);

    // Find the first pan step
    const firstPanStep = steps.find(step => step.action_type === "pan");
    if (firstPanStep) {
      // Use the first pan step's position and zoom
      const coord = {
        lat: parseFloat(firstPanStep.lat),
        lng: parseFloat(firstPanStep.lng)
      };
      const zoom = firstPanStep.zoom_lvl === -1 ? 9 : parseInt(firstPanStep.zoom_lvl, 10);

      googleMapInstance.setCenter(coord);
      googleMapInstance.setZoom(zoom);
    } else {
      // Fallback to bounds calculation if no pan step found
      const bounds = getBoundsOfTripFromHomeAndSteps(
        tripData.home_lat,
        tripData.home_lng,
        steps,
        window.innerWidth,
        window.innerHeight
      );
      googleMapInstance.setCenter(bounds.center);
      googleMapInstance.setZoom(bounds.zoom);
    }

    // Find first animation step to get initial transport type
    const firstAnimationStep = steps.find(step => step.action_type === "animation");
    if (firstAnimationStep) {
      // Store the transport type for later use, but don't show the transport yet
      previousTransportPosition.current = {
        lat: parseFloat(tripData.home_lat),
        lng: parseFloat(tripData.home_lng)
      };
    }
  }, [tripData, steps, transportations, googleMapInstance]);

  useEffect(() => {
    if (!tripData || steps.length === 0 || transportations.length === 0) return;

    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        setShowInstructions(false);
        if (isAnimating) {
          console.log("⏩ Skipping animation...");
          skipAnimationRef.current = true;
        } else {
          advanceStep();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [tripData, steps, transportations, isAnimating, advanceStep]);

  const goBackStep = useCallback(async () => {
    if (currentStepIndex <= 0) return;

    const previousIndex = currentStepIndex - 1;
    const step = steps[previousIndex];
    if (!step) return;

    console.log(`⬅️ Going back to step ${previousIndex}`, step);

    setCurrentStepIndex(previousIndex);

    // Handle different step types
    if (step.action_type === "animation") {
      // 👉 Where we're moving back to
      const prevFrom = findLastValidCoordStep(previousIndex - 1);
      const to = {
        lat: parseFloat(step.lat),
        lng: parseFloat(step.lng),
      };

      const transportImage = getTransportIcon(step.new_transport);

      console.log("⬅️ Animating back from", to, "to", prevFrom);

      // ✅ Get dynamic center and zoom between the two points
      const { center, zoom } = getBoundsBetweenPoints(
        prevFrom,
        to,
        window.innerWidth,
        window.innerHeight
      );

      console.log("📍 Dynamic bounds center:", center, "zoom:", zoom);

      // 👉 Apply zoom and center before animation
      googleMapInstance.setZoom(zoom);
      googleMapInstance.setCenter(center);

      // 👉 Animate backwards (to previous stop)
      await animateTransport(to, prevFrom, transportImage, { reverse: false });

    } else if (step.action_type === "pan" || step.action_type === "zoom") {
      const coord = findLastValidCoordStep(previousIndex);
      googleMapInstance.panTo(coord);
      googleMapInstance.setZoom(parseInt(step.zoom_lvl, 10));

    } else if (step.action_type === "show_images") {
      await showMediaForStop(step);
    }

  }, [
    currentStepIndex,
    steps,
    googleMapInstance,
    showMediaForStop,
    findLastValidCoordStep,
    getTransportIcon,
    animateTransport // ✅ make sure this is useCallback already!
  ]);

  const heading = transportPosition && previousTransportPosition.current
    ? calculateHeading(previousTransportPosition.current, transportPosition)
    : 0;

  // If transport isn't moving (same position), rotate 90°
  const isStandingStill =
    previousTransportPosition.current &&
    transportPosition &&
    previousTransportPosition.current.lat === transportPosition.lat &&
    previousTransportPosition.current.lng === transportPosition.lng;

  const adjustedHeading = isStandingStill ? 90 : heading;

  previousTransportPosition.current = transportPosition;

  const shouldFlip = adjustedHeading > 180;
  const transformStyle = `
  translate(-50%, -50%)
  rotate(${adjustedHeading - 90}deg)
  ${shouldFlip ? 'scaleY(-1)' : ''}
`;

  const expandPreview = (url, isVideo = false) => {
    if (isVideo) {
      setPreviewVideo(url);
    } else {
      setPreviewImage(url);
    }
  };

  const closePreview = () => {
    setPreviewImage(null);
    setPreviewVideo(null);
  };

  // Add useEffect for mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Add function to count media types
  const countMediaTypes = (mediaArray) => {
    return {
      images: mediaArray.filter(m => m.type?.startsWith('image/')).length,
      videos: mediaArray.filter(m => m.type?.startsWith('video/')).length
    };
  };

  // Add function to handle row expansion
  const toggleRowExpansion = (index) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Add function to handle hover
  const handleHoverStart = (media, idx, isAssigned = false, stopIndex = null) => {
    setHoveredThumb(`${isAssigned ? 'assigned' : 'unassigned'}-${stopIndex || idx}`);
  };

  const handleHoverEnd = () => {
    setHoveredThumb(null);
  };

  return (
    <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
      <button
        onClick={() => history.push('/')}
        style={{
          position: "fixed",
          top: "20px",
          left: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          padding: "10px 20px",
          borderRadius: "8px",
          border: "none",
          cursor: "pointer",
          zIndex: 3000,
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          gap: "8px"
        }}
      >
        🏠 Home
      </button>

      <button
        onClick={() => history.push(`/trip-planner?tripId=${tripId}`)}
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          padding: "10px 20px",
          borderRadius: "8px",
          border: "none",
          cursor: "pointer",
          zIndex: 3000,
          fontSize: "16px",
          display: (isAdmin || !tripData?.locked) ? "flex" : "none",
          alignItems: "center",
          gap: "8px"
        }}
      >
        ✏️ Edit Trip
      </button>

      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={{ lat: 59.9139, lng: 10.7522 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) =>
          setGoogleMapInstance(map)
        }
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeId: "hybrid",
          gestureHandling: "greedy",
          zoomControl: true,
        }}
        loadingStrategy="lazyOnload"
      >
        {tripData && (
          <CustomMarker lat={parseFloat(tripData.home_lat)} lng={parseFloat(tripData.home_lng)}>
            <span style={{ fontSize: 32 }}>🏠</span>
          </CustomMarker>
        )}

        {steps.slice(0, currentStepIndex).map((step, index) => {
          const stepLat = parseFloat(step.lat);
          const stepLng = parseFloat(step.lng);
          const homeLat = parseFloat(tripData.home_lat);
          const homeLng = parseFloat(tripData.home_lng);

          const isHomeLocation = Math.abs(stepLat - homeLat) < 0.0001 && Math.abs(stepLng - homeLng) < 0.0001;

          // Calculate stop index by counting animation steps before current step
          const stopIndex = steps.slice(0, index).filter(s => s.action_type === "animation").length;

          return step.lat !== null &&
            step.lng !== null &&
            step.action_type === "animation" &&
            !isHomeLocation && (
              <StopMarker
                key={index}
                lat={stepLat}
                lng={stepLng}
                index={index}
                stopIndex={stopIndex}
                mediaGroups={mediaGroups}
                expandedRows={expandedRows}
                toggleRowExpansion={toggleRowExpansion}
                handleDotClick={() => {}}
                assignSelectedMediaToStop={() => {}}
                currentStep="view"
                countMediaTypes={countMediaTypes}
                hoverTimeout={null}
                setHoverTimeout={() => {}}
                editingIndex={null}
                removeMediaFromGroup={() => {}}
                handleHoverStart={handleHoverStart}
                handleHoverEnd={handleHoverEnd}
                videoRefs={videoRefs}
                hoveredThumb={hoveredThumb}
                selectedMedia={selectedMedia}
                showMediaIndicators={false}
              />
            );
        })}

        {transportPosition && (
          <CustomMarker lat={transportPosition.lat} lng={transportPosition.lng}>
            <img
              src={transportPosition.transportImageUrl}
              alt="Transport"
              style={{
                width: "50px",
                height: "50px",
                transform: transformStyle,
                transition: "transform 0.1s linear",
              }}
            />
          </CustomMarker>
        )}
      </GoogleMapReact>

      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          transform: "translateY(-50%)",
          pointerEvents: "none",        // Let map events through
          zIndex: 3000                  // 👈 Higher than the media display overlay
        }}
      >
        {(currentStepIndex > 0 || hasLoopedOnce) && (
          <button
            onClick={() => {
              setShowInstructions(false);
              goBackStep();
            }}
            style={{
              pointerEvents: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "white",
              fontSize: "28px",
              padding: "15px",
              borderRadius: "50%",
              marginLeft: "20px",
              border: "none",
              cursor: "pointer",
              zIndex: 3001
            }}
          >
            ⬅️
          </button>
        )}

        <div style={{ flex: 1 }} /> {/* spacer */}

        <button
          onClick={() => {
            setShowInstructions(false);
            if (isAnimating) {
              console.log("⏩ Skipping animation...");
              skipAnimationRef.current = true;
              return;
            }

            // If we're showing images and waiting for user to continue
            if (continueHandler) {
              console.log("➡️ Continuing from images...");
              continueHandler();
            } else {
              console.log("➡️ Advancing to next step...");
              advanceStep();
            }
          }}
          style={{
            pointerEvents: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            fontSize: "28px",
            padding: "15px",
            borderRadius: "50%",
            marginRight: "20px",
            border: "none",
            cursor: "pointer",
            zIndex: 3001
          }}
        >
          ➡️
        </button>
      </div>

      {currentMediaDisplay.length > 0 && (
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2000, // 👈 Lower than buttons!
          pointerEvents: "auto"
        }}>
          <div style={{
            display: "flex", gap: "10px", flexWrap: "wrap", maxWidth: "80vw",
            maxHeight: "80vh", overflowY: "auto", justifyContent: "center",
            marginTop: "20px", marginBottom: "20px"
          }}>

            {currentMediaDisplay.map((url, idx) => {
              const isVideo = url.match(/\.(mp4|mov|avi|webm)(\?.*)?$/i);

              return (
                <div
                  key={idx}
                  style={{ 
                    margin: '10px',
                    position: 'relative',
                    cursor: isVideo ? 'default' : 'pointer'
                  }}
                  onClick={() => !isVideo && expandPreview(url)}
                  onTouchStart={(e) => {
                    if (!isVideo) {
                      e.currentTarget.dataset.touchStartTime = Date.now();
                    }
                  }}
                  onTouchEnd={(e) => {
                    if (!isVideo) {
                      const touchStartTime = parseInt(e.currentTarget.dataset.touchStartTime);
                      const touchDuration = Date.now() - touchStartTime;

                      if (touchDuration > 500) {
                        e.preventDefault();
                        expandPreview(url);
                      }
                    }
                  }}
                >
                  {isVideo ? (
                    <video
                      src={url}
                      autoPlay
                      loop
                      muted
                      playsInline
                      controls={isMobile}
                      style={{
                        width: 260,
                        height: 260,
                        objectFit: "cover",
                        border: "2px solid white",
                        backgroundColor: "#000"
                      }}
                      onMouseEnter={(e) => {
                        if (!isMobile) {
                          e.currentTarget.controls = true;
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (!isMobile) {
                          e.currentTarget.controls = false;
                        }
                      }}
                    />
                  ) : (
                    <img
                      src={url}
                      alt={`Gallery ${idx}`}
                      style={{
                        width: 260,
                        height: 260,
                        objectFit: "cover",
                        border: "2px solid white",
                        touchAction: "none"
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>

          <button onClick={() => continueHandler && continueHandler()} style={{
            marginTop: 20, fontSize: 18, padding: "12px 24px",
            backgroundColor: "#4CAF50", color: "white", borderRadius: 8, border: "none", cursor: "pointer"
          }}>
            ➡️ Continue
          </button>
        </div>
      )}

      {previewImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3000,
            cursor: "pointer"
          }}
          onClick={closePreview}
        >
          <img
            src={previewImage}
            alt="Preview"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain"
            }}
          />
          <button
            onClick={closePreview}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              background: "none",
              border: "none",
              color: "white",
              fontSize: "24px",
              cursor: "pointer",
              padding: "10px"
            }}
          >
            ✕
          </button>
        </div>
      )}

      {previewVideo && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3000,
            cursor: "pointer"
          }}
          onClick={closePreview}
        >
          <video
            src={previewVideo}
            controls
            autoPlay
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain"
            }}
          />
          <button
            onClick={closePreview}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              background: "none",
              border: "none",
              color: "white",
              fontSize: "24px",
              cursor: "pointer",
              padding: "10px"
            }}
          >
            ✕
          </button>
        </div>
      )}

      {showInstructions && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "20px 40px",
            borderRadius: "12px",
            fontSize: "18px",
            zIndex: 3000,
            textAlign: "center",
            maxWidth: "80%",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
          }}
        >
          {isMobile ? "Naviger med pilene på siden av skjermen" : "Naviger med spacebar eller pilene på siden av skjermen"}
        </div>
      )}
    </div>
  );
}