import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { useHistory, useLocation } from 'react-router-dom';
import TransportDropdown from "./TransportDropdown";
import { getBoundsForSteps, getBoundsBetweenPoints, fetchRoute } from "../utils/mapHelper";
import { apiFetch } from "../utils/api";
import exifr from 'exifr';
import StopMarker from './StopMarker';

// Function to calculate distance between two points in kilometers
const calculateDistance = (point1, point2) => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = (point2.lat - point1.lat) * Math.PI / 180;
  const dLon = (point2.lng - point1.lng) * Math.PI / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

// Function to extract GPS data from a file
const extractGPSData = async (file) => {
  try {
    const exif = await exifr.parse(file);
    if (exif && exif.latitude && exif.longitude) {
      return {
        lat: exif.latitude,
        lng: exif.longitude
      };
    }
    return null;
  } catch (error) {
    console.warn("No GPS data found in file:", error);
    return null;
  }
};

// Custom marker component
const CustomMarker = ({ children, offsetX = 0, offsetY = 0 }) => (
  <div style={{
    position: "relative",
    transform: `translate(${offsetX}px, ${offsetY}px)`,
    transformOrigin: "center center"
  }}>
    {children}
  </div>
);

export default function TripPlannerPage() {
  const mapRef = useRef();
  const videoRefs = useRef({});
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tripId = queryParams.get('tripId');
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const clientId = useSelector((state) => state.clientId);

  const [transportations, setTransportations] = useState([]);
  const [currentStep, setCurrentStep] = useState("selectHome");

  const [homeLocation, setHomeLocation] = useState(() => {
    // Try to get home location from localStorage on component mount
    const savedHomeLocation = localStorage.getItem('homeLocation');
    return savedHomeLocation ? JSON.parse(savedHomeLocation) : null;
  });
  const [homeConfirmed, setHomeConfirmed] = useState(false);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // -1 means adding new, null means not editing
  const [selectedTransportName, setSelectedTransportName] = useState(null);
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [mediaGroups, setMediaGroups] = useState({}); // Grouped media { pointIndex: [files] }
  const [unassignedMedia, setUnassignedMedia] = useState([]);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [hoveredThumb, setHoveredThumb] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tripName, setTripName] = useState("");
  const [tripData, setTripData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasPerformedFirstAssignment, setHasPerformedFirstAssignment] = useState(false);
  const [isEditingTripName, setIsEditingTripName] = useState(false);
  const [isEditingHome, setIsEditingHome] = useState(false);
  const [pendingImageMoves, setPendingImageMoves] = useState([]); // Track image moves for reordering
  const hasCenteredMapRef = useRef(false); // Add ref to track if we've centered the map
  const [routes, setRoutes] = useState([]); // Add this line after other state declarations
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  // Add new state for expanded rows
  const [expandedRows, setExpandedRows] = useState({});
  const [allExpanded, setAllExpanded] = useState(false);

  // Add new function to toggle all rows
  const toggleAllRows = () => {
    const newExpandedState = {};
    selectedPlaces.forEach((_, index) => {
      newExpandedState[index] = !allExpanded;
    });
    setExpandedRows(newExpandedState);
    setAllExpanded(!allExpanded);
  };

  // Add function to toggle row expansion
  const toggleRowExpansion = (index) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Add function to count media types
  const countMediaTypes = (mediaArray) => {
    return mediaArray.reduce((acc, media) => {
      if (media.type.startsWith('video/')) {
        acc.videos++;
      } else {
        acc.images++;
      }
      return acc;
    }, { images: 0, videos: 0 });
  };

  const GOOGLE_API_KEY = "AIzaSyA7QJursebL1QtVEKAjr2q-AGfwvBXLmjk";

  const fileInputRef = useRef(null);
  const tripNameInputRef = useRef(null); // Add this line

  useEffect(() => {
    // Focus the trip name input when component mounts
    if (tripNameInputRef.current) {
      tripNameInputRef.current.focus();
    }
  }, []); // Empty dependency array means this runs once on mount

  const defaultCenter = { lat: 59.9139, lng: 10.7522 };
  const zoomLevel = 5;

  const [googleMapInstance, setGoogleMapInstance] = useState(null);
  const [maps, setMaps] = useState(null);
  const polylinesRef = useRef([]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await apiFetch("https://hawkon.eu/api/checkSession", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clientId }),
        });
        const data = await response.json();
        if (data.authenticated) {
          setIsAdmin(data.isAdmin);
        }
      } catch (error) {
        console.error("❌ Error checking session:", error);
      }
    };
    checkSession();
  }, [clientId]);

  // ✅ Fetch transportation options
  useEffect(() => {
    const fetchTransportations = async () => {
      try {
        const res = await apiFetch(`https://hawkon.eu/api/admin/transportations?clientId=${clientId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });
        const data = await res.json();
        console.log("Fetched transportations");

        setTransportations(data);

        // ✅ Find airplane transport (case-insensitive)
        const airplaneTransport = data.find((transport) =>
          transport.name.toLowerCase().includes("airplane")
        );

        // ✅ Default to airplane, otherwise first transport
        if (data.length > 0) {
          setSelectedTransportName(airplaneTransport?.name || data[0].name);
        }
      } catch (err) {
        console.error("Failed to fetch transportations", err);
      }
    };
    fetchTransportations();
  }, [clientId]);

  const dragCounter = useRef(0);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const mediaWithUrls = useMemo(() => {
    return unassignedMedia.map(media => ({
      file: media,
      url: media.isBackendMedia ? media.url : URL.createObjectURL(media.file)
    }));
  }, [unassignedMedia]);

  // Clean up object URLs on unmount or when media changes
  useEffect(() => {
    return () => {
      mediaWithUrls.forEach(({ file, url }) => {
        if (!file.isBackendMedia) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [mediaWithUrls]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleMediaUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    dragCounter.current++;
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    dragCounter.current--;

    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const getThumbnailWrapperStyle = (isSelected, isHovered) => ({
    position: "relative",
    width: "80px",
    height: "80px",
    borderRadius: "6px",
    overflow: "visible", // Changed from "hidden" to "visible"
    cursor: "pointer",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    transform: isSelected ? "scale(1.05)" : "scale(1)",
    boxShadow: isSelected
      ? "0 0 10px 2px rgba(0, 255, 0, 0.7)" // lime glow
      : isHovered
        ? "0 0 6px rgba(0, 0, 0, 0.4)" // subtle shadow on hover
        : "none",
    margin: "0 5px", // Added margin to prevent overlap
  });


  const fetchClosestCity = async (lat, lon) => {
    try {
      return "temp"
      const response = await apiFetch(`https://hawkon.eu/api/reverse-geocode?lat=${lat}&lng=${lon}`);
      const data = await response.json();

      if (data.features && data.features.length > 0) {
        const cityInfo = data.features[0].properties;
        console.log("✅ Closest City Info:", cityInfo);

        const city = cityInfo.locality || cityInfo.city || cityInfo.county || "Unknown location";
        setSelectedCityName(city);
        return city;
      } else {
        setSelectedCityName("Unknown location");
        console.warn("⚠️ No city found for these coordinates.");
        return "Unknown location";
      }
    } catch (error) {
      setSelectedCityName("Unknown location");

      console.error("❌ Error fetching city:", error);
      return "Error fetching location";
    }
  };

  const autoGroupMedia = async (files) => {
    const grouped = { ...mediaGroups }; // ✅ start with existing groups
    const unassigned = [...unassignedMedia]; // ✅ start with existing unassigned

    files.forEach((media) => {
      // Dummy coords (replace with EXIF GPS later)
      const fakeCoords = {
        lat: homeLocation.lat + Math.random() * 0.1,
        lng: homeLocation.lng + Math.random() * 0.1,
      };

      let closestIdx = null;
      let closestDist = 50000; // 50km radius

      selectedPlaces.forEach((place, idx) => {
        const dist = getDistance(fakeCoords, place);
        if (dist < closestDist) {
          closestDist = dist;
          closestIdx = idx;
        }
      });

      if (closestIdx !== null) {
        // Instead of adding to mediaGroups, just add to unassigned
        unassigned.push(media);
      } else {
        unassigned.push(media);
      }
    });

    console.log("✅ Grouped media:", grouped);
    setMediaGroups(grouped);
    setUnassignedMedia(unassigned);
  };

  const toggleSelectMedia = (media) => {
    // If it's a video, pause it at the beginning
    if (media.type?.startsWith('video/')) {
      // Find all video elements
      const videoElements = document.querySelectorAll('video');
      const mediaContainer = document.querySelector(`[data-media-id="${media.isBackendMedia ? media.originalPath || media.url : media.file.name}"]`);

      // Find the one that matches our media by checking if the video element is in the same container as our media
      const videoElement = Array.from(videoElements).find(video => {
        // Get the parent container of the video
        const videoContainer = video.closest('[data-media-id]');
        return videoContainer === mediaContainer;
      });

      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    }

    setSelectedMedia((prev) =>
      prev.some(m => m === media)
        ? prev.filter((m) => m !== media)
        : [...prev, media]
    );
  };

  const assignSelectedMediaToStop = (stopIndex) => {
    if (!selectedMedia.length) return;

    // Set first assignment flag
    setHasPerformedFirstAssignment(true);

    console.log("📸 Current unassignedMedia:", unassignedMedia);
    console.log("📸 Assigning selected media to stop:", stopIndex);
    console.log("📸 Selected media:", selectedMedia);

    const updatedGroups = { ...mediaGroups };

    // If this stop doesn't have a group yet, create one
    if (!updatedGroups[stopIndex]) {
      updatedGroups[stopIndex] = [];
    }

    // Process each selected media file
    selectedMedia.forEach(media => {
      console.log("Processing media for reassignment:", media);

      // If it's a backend media, update the URL to reflect the new stop index
      if (media.isBackendMedia) {
        // Extract the relative path from the full URL
        const urlParts = media.url.split('?')[0].split('/');
        const mediaIndex = urlParts.indexOf('media');
        if (mediaIndex !== -1) {
          // Get the filename and extension
          const filename = urlParts[urlParts.length - 1];
          // Create new relative path with current stop index
          const newRelativePath = `media/${urlParts[mediaIndex + 1]}/${stopIndex}/${filename}`;

          // Create updated media object with new relative path but keep original URL for display
          const updatedMedia = {
            ...media,
            url: media.url, // Keep the original full URL for display
            relativePath: newRelativePath, // Store the new relative path for the move operation
            originalPath: media.url.split('?')[0] // Store original path without query params
          };

          updatedGroups[stopIndex].push(updatedMedia);
        } else {
          console.warn("Could not extract media path from URL:", media.url);
          // Add the media as is if we can't process the URL
          updatedGroups[stopIndex].push(media);
        }
      } else {
        // For frontend files, just add them as is
        updatedGroups[stopIndex].push(media);
      }
    });

    console.log("📸 Updated media groups:", updatedGroups);
    setMediaGroups(updatedGroups);

    // Remove assigned media from unassignedMedia
    setUnassignedMedia(prev => {
      const updated = prev.filter(media => !selectedMedia.includes(media));
      console.log("📸 Updated unassignedMedia:", updated);
      return updated;
    });

    setSelectedMedia([]);
  };

  // Dummy distance function (replace with haversine if needed)
  const getDistance = (a, b) => {
    const dx = a.lat - b.lat;
    const dy = a.lng - b.lng;
    return Math.sqrt(dx * dx + dy * dy) * 111000; // Rough conversion to meters
  };


  // Add useEffect to load trip data when tripId is present
  useEffect(() => {
    // Skip if no tripId is provided or if tripId is "null"
    if (!tripId || tripId === "null") {
      console.log("No valid tripId provided, skipping trip data load");
      return;
    }

    // Skip if already loading or if we already have trip data
    if (isLoading || tripData) {
      return;
    }

    const loadTripData = async () => {
      setIsLoading(true);
      try {
        console.log(`🔄 Loading trip data for tripId: ${tripId}`);
        const res = await apiFetch(`https://hawkon.eu/api/getTrip?tripId=${tripId}&clientId=${clientId}`);

        // Check if response is 404 or unauthorized
        if (res.status === 404 || res.status === 401) {
          console.log("❌ Session invalid or trip not found, redirecting to home");
          history.push('/');
          return;
        }

        const data = await res.json();

        if (!data.success) {
          throw new Error("Failed to load trip data");
        }

        console.log("✅ Trip data loaded successfully:", {
          tripName: data.trip.name,
          stops: data.pathSteps.filter(step => step.action_type === "animation").length,
          totalSteps: data.pathSteps.length
        });

        // Process media groups from path steps
        const newMediaGroups = {};
        data.pathSteps.forEach((step, index) => {
          if (step.images && step.images.length > 0) {
            // Process each image path to determine its stop index
            step.images.forEach(imagePath => {
              // Extract stop index from path (e.g., 'media/gallery_182/0/img_2569-1742643019235.jpeg')
              const stopIndexMatch = imagePath.match(/(?:^|\/)media\/[^/]+\/(\d+)\//);
              if (stopIndexMatch) {
                const stopIndex = parseInt(stopIndexMatch[1], 10);
                // console.log(`📸 Assigning image ${imagePath} to stop ${stopIndex}`);

                if (!newMediaGroups[stopIndex]) {
                  newMediaGroups[stopIndex] = [];
                }

                // Determine media type from file extension
                const isVideo = /\.(mp4|mov|avi|webm)(\?.*)?$/i.test(imagePath);
                const mediaType = isVideo ? 'video/mp4' : 'image/jpeg';

                // Create a properly formatted media object
                newMediaGroups[stopIndex].push({
                  type: mediaType,
                  url: imagePath,
                  isBackendMedia: true
                });
              } else {
                console.warn(`⚠️ Could not extract stop index from path: ${imagePath}`);
              }
            });
          }
        });

        // Only log and set media groups once
        if (Object.keys(newMediaGroups).length > 0) {
          console.log("📸 Final media groups:", newMediaGroups);
          
          // Calculate and log total counts
          const totalCounts = Object.values(newMediaGroups).reduce((acc, group) => {
            const counts = countMediaTypes(group);
            return {
              images: acc.images + counts.images,
              videos: acc.videos + counts.videos
            };
          }, { images: 0, videos: 0 });
          
          console.log(`📊 Total media: ${totalCounts.images} images, ${totalCounts.videos} videos (${totalCounts.images + totalCounts.videos} total)`);
          
          setMediaGroups(newMediaGroups);
        }

        // Set other state updates
        setTripData(data);
        setTripName(data.trip.name);
        setHomeLocation({
          lat: parseFloat(data.trip.home_lat),
          lng: parseFloat(data.trip.home_lng)
        });
        setHomeConfirmed(true);

        // Filter path steps to only include "animation" steps for places
        const places = data.pathSteps
          .filter(step => {
            // Only include animation steps
            if (step.action_type !== "animation") return false;

            // Check if this is the home location
            const isHomeLocation = Math.abs(parseFloat(step.lat) - parseFloat(data.trip.home_lat)) < 0.0001 &&
              Math.abs(parseFloat(step.lng) - parseFloat(data.trip.home_lng)) < 0.0001;

            // Exclude home location and return home step
            return !isHomeLocation;
          })
          .map(step => ({
            lat: parseFloat(step.lat),
            lng: parseFloat(step.lng),
            transport: step.new_transport
          }));

        console.log("📍 Loaded places:", places);
        setSelectedPlaces(places);

        // Set to mediaUpload step
        console.log("🔄 Setting current step to mediaUpload");
        setCurrentStep("mediaUpload");

      } catch (error) {
        console.error("❌ Error loading trip data:", error);
        // If we get an error, redirect to home
        history.push('/');
      } finally {
        setIsLoading(false);
      }
    };

    loadTripData();
  }, [tripId, clientId, transportations, tripData, isLoading, history]);

  // Add new useEffect to handle map centering when both trip data and map instance are available
  useEffect(() => {
    if (!tripData || !googleMapInstance || selectedPlaces.length === 0 || hasCenteredMapRef.current) return;

    // Calculate bounds using selectedPlaces
    const bounds = new window.google.maps.LatLngBounds();
    selectedPlaces.forEach(place => {
      bounds.extend(new window.google.maps.LatLng(place.lat, place.lng));
    });

    // Add some padding to the bounds
    const padding = 0.1; // degrees
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    bounds.extend(new window.google.maps.LatLng(ne.lat() + padding, ne.lng() + padding));
    bounds.extend(new window.google.maps.LatLng(sw.lat() - padding, sw.lng() - padding));

    // Fit map to bounds
    googleMapInstance.fitBounds(bounds);

    // Set a minimum zoom level to prevent too much zooming out
    googleMapInstance.setOptions({
      minZoom: 3,
      maxZoom: 15
    });

    // Mark that we've centered the map
    hasCenteredMapRef.current = true;

  }, [tripData, googleMapInstance, selectedPlaces]);

  // Add effect to manage polylines
  useEffect(() => {
    if (!googleMapInstance || !maps || !routes.length) return;

    // Clear existing polylines
    polylinesRef.current.forEach(polyline => polyline.setMap(null));
    polylinesRef.current = [];

    // Create new polylines
    routes.forEach(route => {
      if (!route || route.length < 2) return;

      const polyline = new maps.Polyline({
        path: route,
        map: googleMapInstance,
        strokeColor: '#4285F4',
        strokeOpacity: 0.8,
        strokeWeight: 3,
      });

      polylinesRef.current.push(polyline);
    });

    // Cleanup function
    return () => {
      polylinesRef.current.forEach(polyline => polyline.setMap(null));
      polylinesRef.current = [];
    };
  }, [routes, googleMapInstance, maps]);

  // Add this helper function before the saveTrip function
  const getRelativePathFromUrl = (url) => {
    try {
      // If it's already a relative path, return it
      if (!url.startsWith('http')) {
        return url;
      }

      // If it's a full URL, extract the path after 'media/'
      const mediaIndex = url.indexOf('media/');
      if (mediaIndex !== -1) {
        return url.substring(mediaIndex);
      }

      // If no 'media/' found, try to extract path after the last slash
      const lastSlashIndex = url.lastIndexOf('/');
      if (lastSlashIndex !== -1) {
        return url.substring(lastSlashIndex + 1);
      }

      return url;
    } catch (error) {
      console.warn('Invalid URL:', url);
      return url;
    }
  };

  const saveTrip = async () => {
    setIsSaving(true);
    try {
      if (!homeLocation || !tripName.trim()) {
        alert("Trip name and home location required.");
        return;
      }

      let currentTripId = tripId;
      let isNewTrip = !currentTripId;
      try {
        // ✅ 1. Determine initial_lat / initial_lng from first stop (if available)
        const hasStops = selectedPlaces.length > 0;
        const initialLat = hasStops ? parseFloat(selectedPlaces[0].lat) : parseFloat(homeLocation.lat);
        const initialLng = hasStops ? parseFloat(selectedPlaces[0].lng) : parseFloat(homeLocation.lng);

        // 🔎 Determine most frequent transport
        const transportCounts = selectedPlaces.reduce((acc, place) => {
          const transportName = place.transport;
          if (transportName !== undefined && transportName !== null) {
            acc[transportName] = (acc[transportName] || 0) + 1;
          }
          return acc;
        }, {});

        const mostFrequentTransport = Object.entries(transportCounts).reduce((max, [transport, count]) => {
          return count > (max.count || 0) ? { transport, count } : max;
        }, {}).transport || "Unknown";

        if (isNewTrip) {
          // Create new trip
          const res = await apiFetch("https://hawkon.eu/api/admin/addTrip", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              name: tripName,
              initial_lat: initialLat,
              initial_lng: initialLng,
              home_lat: parseFloat(homeLocation.lat),
              home_lng: parseFloat(homeLocation.lng),
              initial_zoom_lvl: zoomLevel || 5,
              transport: mostFrequentTransport,
              clientId: clientId,
            }),
          });

          const data = await res.json();
          if (!data.success || !data.trips?.length) throw new Error("Failed to create trip");
          currentTripId = data.trips[0].id;
        }

        // Now that we have currentTripId, handle the image moves
        if (pendingImageMoves.length > 0) {
          console.log('🔄 Processing pending image moves:', pendingImageMoves);
          for (const move of pendingImageMoves) {
            try {
              console.log(`📤 Sending move request for: ${move.from} -> ${move.to}`);
              const moveRes = await apiFetch("https://hawkon.eu/api/admin/moveTripMedia", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  clientId,
                  tripId: currentTripId,
                  moves: [move]
                })
              });

              if (!moveRes.ok) {
                throw new Error(`Failed to move media: ${moveRes.status} ${moveRes.statusText}`);
              }

              const moveResult = await moveRes.json();
              console.log('✅ Move result:', moveResult);
            } catch (error) {
              console.error('❌ Error moving media:', error);
              // Continue with other operations even if one move fails
            }
          }
          console.log('✅ All pending moves processed');
          // Clear pending moves after processing
          setPendingImageMoves([]);
        }

        // Handle media updates
        let uploadData = { uploadedFiles: [] };
        let galleryName = null;
        let imagesToMove = [];

        // Count total images in current state
        const totalImages = Object.values(mediaGroups).reduce((acc, mediaForStop) => acc + (mediaForStop?.length || 0), 0);

        if (totalImages > 0 || unassignedMedia.length > 0) {
          galleryName = `gallery_${currentTripId}`;

          // Get existing images from trip data
          const existingImagesByStep = {};
          if (tripData?.pathSteps) {
            console.log('📸 Current trip data path steps:', tripData.pathSteps);
            console.log('📍 Current selected places:', selectedPlaces);

            // Only look at show_images steps to get the correct stop indices
            tripData.pathSteps.forEach((step, index) => {
              if (step.action_type === "show_images" && step.images && step.images.length > 0) {
                // Extract the stop index from the gallery name (e.g., gallery_123/0/...)
                const galleryMatch = step.gallery_name?.match(/gallery_\d+\/(\d+)/);
                if (galleryMatch) {
                  const stopIndex = parseInt(galleryMatch[1]);
                  console.log(`📸 Found images for stop ${stopIndex} in step ${index}`);
                  existingImagesByStep[stopIndex] = step.images;
                }
              }
            });
          }

          // Track which images need to be deleted
          const imagesToDelete = [];

          // Check for removed images in unassigned media
          unassignedMedia.forEach(media => {
            if (media.isBackendMedia && media.originalPath) {
              // Extract relative path from the full URL
              const urlParts = media.originalPath.split('?')[0].split('/');
              const mediaIndex = urlParts.indexOf('media');
              if (mediaIndex !== -1) {
                const relativePath = urlParts.slice(mediaIndex).join('/');
                console.log(`🗑️ Marking for deletion:`, relativePath);
                imagesToDelete.push(relativePath);
              } else {
                console.warn("Could not extract media path from URL:", media.originalPath);
                imagesToDelete.push(media.originalPath);
              }
            }
          });

          // Check for images that were moved between stops
          Object.entries(mediaGroups).forEach(([stopIndex, mediaForStop]) => {
            mediaForStop.forEach(media => {
              if (media.isBackendMedia && media.originalPath && media.relativePath) {
                // Extract the relative path from the original path in the same format as the "to" value
                const urlParts = media.originalPath.split('?')[0].split('/');
                const mediaIndex = urlParts.indexOf('media');
                if (mediaIndex !== -1) {
                  const fromPath = urlParts.slice(mediaIndex).join('/');
                  // Skip if from and to paths are identical
                  if (fromPath === media.relativePath) {
                    console.log(`✅ Skipping move - paths are identical: ${fromPath}`);
                    return;
                  }
                  console.log(`🔄 Marking for move: ${fromPath} -> ${media.relativePath}`);
                  imagesToMove.push({
                    from: fromPath,
                    to: media.relativePath
                  });
                } else {
                  console.warn("Could not extract media path from URL:", media.originalPath);
                }
              }
            });
          });

          // Check for images that were removed from steps
          Object.entries(mediaGroups).forEach(([stopIndex, mediaForStop]) => {
            const existingImages = existingImagesByStep[stopIndex] || [];
            const currentImagePaths = mediaForStop
              .filter(media => media.isBackendMedia)
              .map(media => media.url);

            existingImages.forEach(existingImage => {
              if (!currentImagePaths.includes(existingImage)) {
                console.log(`🗑️ Marking for deletion (removed from step): ${existingImage}`);
                imagesToDelete.push(existingImage);
              }
            });
          });

          // Check for images from removed stops
          Object.entries(existingImagesByStep).forEach(([stopIndex, images]) => {
            // Only delete images from stops that are truly removed (beyond current stops)
            // and not from stops that are being edited
            const stopIndexNum = parseInt(stopIndex);
            console.log(`🔍 Checking stop ${stopIndexNum} against current stops (${selectedPlaces.length})`);

            // Only delete if this stop index is beyond our current number of stops
            // AND if it's not a stop we're currently editing
            if (stopIndexNum > selectedPlaces.length - 1) {
              console.log(`🗑️ Marking for deletion (removed stop ${stopIndex}):`, images);
              imagesToDelete.push(...images);
            } else {
              console.log(`✅ Preserving images for stop ${stopIndex} as it's still active`);
            }
          });

          // Delete removed images from Spaces
          if (imagesToDelete.length > 0) {
            console.log(`🗑️ Deleting ${imagesToDelete.length} images from Spaces:`, imagesToDelete);
            try {
              const deleteRes = await apiFetch("https://hawkon.eu/api/admin/deleteTripMedia", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  clientId,
                  tripId: currentTripId,
                  imagePaths: imagesToDelete
                })
              });

              if (!deleteRes.ok) {
                throw new Error("Failed to delete removed images");
              }

              const deleteResult = await deleteRes.json();
              console.log("✅ Delete result:", deleteResult);
            } catch (error) {
              console.error("Failed to delete removed images:", error);
              alert("Failed to delete removed images. Please try again.");
              return;
            }
          }

          // Move images between stops in Spaces
          if (imagesToMove.length > 0) {
            console.log(`🔄 Moving ${imagesToMove.length} images in Spaces:`, imagesToMove);
            try {
              const moveRes = await apiFetch("https://hawkon.eu/api/admin/moveTripMedia", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  clientId,
                  tripId: currentTripId,
                  moves: imagesToMove
                })
              });

              if (!moveRes.ok) {
                throw new Error("Failed to move images");
              }

              const moveResult = await moveRes.json();
              console.log("✅ Move result:", moveResult);

              // Update media URLs in mediaGroups after successful move
              const updatedGroups = { ...mediaGroups };
              Object.entries(updatedGroups).forEach(([stopIndex, mediaForStop]) => {
                updatedGroups[stopIndex] = mediaForStop.map(media => {
                  if (media.isBackendMedia && media.relativePath) {
                    // Find the corresponding move operation
                    const move = imagesToMove.find(m => m.to === media.relativePath);
                    if (move) {
                      // Update the URL to reflect the new path
                      const baseUrl = 'https://travel-journal.ams3.cdn.digitaloceanspaces.com/';
                      return {
                        ...media,
                        url: `${baseUrl}${media.relativePath}`,
                        originalPath: `${baseUrl}${media.relativePath}`
                      };
                    }
                  }
                  return media;
                });
              });
              setMediaGroups(updatedGroups);

            } catch (error) {
              console.error("Failed to move images:", error);
              alert("Failed to move images. Please try again.");
              return;
            }
          }

          // Prepare form data for upload
          const formData = new FormData();
          formData.append("galleryName", galleryName);
          formData.append("clientId", clientId);

          // Process each step's images
          selectedPlaces.forEach((place, stopIndex) => {
            const mediaForStop = mediaGroups[stopIndex] || [];

            // Add new images to form data
            mediaForStop.forEach((media) => {
              if (!media.isBackendMedia) {
                const fieldName = `mediaFiles-${stopIndex}`;
                formData.append(fieldName, media.file);
              }
            });
          });

          // Upload new images with progress tracking
          const hasMediaFiles = Array.from(formData.keys()).some(key => key.startsWith('mediaFiles-'));
          if (hasMediaFiles) {
            setIsUploading(true);
            setUploadProgress(0);
            setUploadStatus('Preparing upload...');

            try {
              // Calculate total file size before upload
              let totalSize = 0;
              Array.from(formData.values()).forEach(file => {
                if (file instanceof File) {
                  totalSize += file.size;
                }
              });
              setTotalUploadSize(totalSize);
              const totalMB = (totalSize / (1024 * 1024)).toFixed(1);
              setUploadStatus(`Starting upload of ${totalMB}MB...`);

              // Create XMLHttpRequest to handle upload with progress
              const xhr = new XMLHttpRequest();
              const uploadPromise = new Promise((resolve, reject) => {
                xhr.upload.addEventListener('progress', (event) => {
                  if (event.lengthComputable) {
                    const progress = Math.round((event.loaded * 100) / event.total);
                    const uploadedMB = (event.loaded / (1024 * 1024)).toFixed(1);
                    setUploadProgress(progress);
                    setUploadStatus(`Uploading files... ${progress}% (${uploadedMB}MB / ${totalMB}MB)`);
                  }
                });

                xhr.addEventListener('load', () => {
                  if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.responseText));
                  } else {
                    reject(new Error(`Upload failed with status ${xhr.status}`));
                  }
                });

                xhr.addEventListener('error', () => {
                  reject(new Error('Upload failed'));
                });
              });

              // Start the upload
              xhr.open('POST', 'https://hawkon.eu/api/admin/uploadTripMedia');
              xhr.send(formData);

              // Wait for upload to complete
              uploadData = await uploadPromise;

              if (!uploadData.success) {
                throw new Error("Failed to upload media files.");
              }

              setUploadStatus('Processing uploaded files...');
              setUploadProgress(100);

              // Small delay to show 100% progress
              await new Promise(resolve => setTimeout(resolve, 500));
            } catch (error) {
              console.error("Upload error:", error);
              throw error;
            } finally {
              setIsUploading(false);
              setTotalUploadSize(0);
            }
          }
        }

        // ✅ 4. Prepare steps
        const steps = [];

        // Add initial pan step to center between home and first destination
        if (selectedPlaces.length > 0) {
          const { center, zoom } = getBoundsBetweenPoints(
            { lat: parseFloat(homeLocation.lat), lng: parseFloat(homeLocation.lng) },
            { lat: parseFloat(selectedPlaces[0].lat), lng: parseFloat(selectedPlaces[0].lng) },
            window.innerWidth,
            window.innerHeight
          );

          // Get the transport for the first destination
          const firstTransport = selectedPlaces[0].transport;
          const transport = transportations.find(t => t.name === firstTransport);

          steps.push({
            actionType: "pan",
            lat: center.lat,
            lng: center.lng,
            zoom_lvl: zoom,
            newTransport: transport ? transport.name : null, // Add transport here so it appears during initial pan
            directionsFilename: null,
            galleryName: null,
            images: [],
          });
        }

        // Add animation step to first destination
        if (selectedPlaces.length > 0) {
          steps.push({
            actionType: "animation",
            lat: parseFloat(selectedPlaces[0].lat),
            lng: parseFloat(selectedPlaces[0].lng),
            zoom_lvl: null,
            newTransport: selectedPlaces[0].transport,
            directionsFilename: null,
            galleryName: null,
            images: [],
          });
        }

        // Process each destination
        selectedPlaces.forEach((place, index) => {
          const mediaForStop = mediaGroups[index] || [];
          const existingImagesForStop = tripData?.pathSteps?.[index]?.images || [];
          const uploadedImagesForStop = (uploadData.uploadedFiles || []).filter(f => f.stopIndex === index);
          const totalImagesForStop = existingImagesForStop.length + uploadedImagesForStop.length + mediaForStop.length;

          // If this stop has images, add pan and show_images steps
          if (totalImagesForStop > 0) {
            // Pan step to center on this stop
            steps.push({
              actionType: "pan",
              lat: parseFloat(place.lat),
              lng: parseFloat(place.lng),
              zoom_lvl: 12,
              newTransport: null,
              directionsFilename: null,
              galleryName: null,
              images: [],
            });

            // Show images step
            console.log(`\n📸 Creating show_images step for stop ${index}:`);

            // Get existing images from both tripData and mediaGroups
            const existingImagesFromTripData = tripData?.pathSteps?.[index]?.images || [];
            const existingImagesFromMediaGroups = mediaGroups[index] || [];

            // Only use images from mediaGroups for existing images, as they contain the most up-to-date information
            const existingImages = existingImagesFromMediaGroups
              .filter(media => media.isBackendMedia)
              .map(media => {
                // Use relativePath if available, otherwise fall back to URL
                if (media.relativePath) {
                  return media.relativePath;
                }
                // Extract relative path from the full URL
                if (media.url.startsWith('http')) {
                  const url = new URL(media.url);
                  return url.pathname.substring(1);
                }
                return media.url;
              });

            const uploadedImagesForStop = (uploadData.uploadedFiles || []).filter(f => f.stopIndex === index);

            console.log('Existing images from trip data:', existingImagesFromTripData);
            console.log('Existing images from media groups:', existingImagesFromMediaGroups);
            console.log('Combined existing images:', existingImages);
            console.log('Uploaded images:', uploadedImagesForStop);

            // Create the final images array by combining existing and uploaded images
            const finalImages = [
              ...existingImages,
              ...uploadedImagesForStop.map(f => {
                // Extract the path after 'media/' from the fullPath
                const mediaIndex = f.fullPath.indexOf('media/');
                if (mediaIndex !== -1) {
                  return f.fullPath.substring(mediaIndex);
                }
                return f.fullPath;
              })
            ];

            // Update image paths based on successful moves
            const updatedFinalImages = finalImages.map(imagePath => {
              // Find if this image was moved
              const move = pendingImageMoves.find(m => m.from === imagePath);
              if (move) {
                console.log(`📸 Using moved path for image: ${move.to}`);
                return move.to;
              }
              return imagePath;
            });

            console.log('Final images array:', updatedFinalImages);

            steps.push({
              actionType: "show_images",
              lat: parseFloat(place.lat),
              lng: parseFloat(place.lng),
              zoom_lvl: null,
              newTransport: null,
              directionsFilename: null,
              galleryName: `gallery_${currentTripId}`,
              images: updatedFinalImages,
            });
          }

          // If there's a next stop, add pan step to center between current and next
          if (index < selectedPlaces.length - 1) {
            const nextPlace = selectedPlaces[index + 1];
            const { center, zoom } = getBoundsBetweenPoints(
              { lat: parseFloat(place.lat), lng: parseFloat(place.lng) },
              { lat: parseFloat(nextPlace.lat), lng: parseFloat(nextPlace.lng) },
              window.innerWidth,
              window.innerHeight
            );

            steps.push({
              actionType: "pan",
              lat: center.lat,
              lng: center.lng,
              zoom_lvl: zoom,
              newTransport: null,
              directionsFilename: null,
              galleryName: null,
              images: [],
            });

            // Animation step to next destination
            steps.push({
              actionType: "animation",
              lat: parseFloat(nextPlace.lat),
              lng: parseFloat(nextPlace.lng),
              zoom_lvl: null,
              newTransport: nextPlace.transport,
              directionsFilename: null,
              galleryName: null,
              images: [],
            });
          }
        });

        // Add final pan step between last stop and home
        if (selectedPlaces.length > 0) {
          // Add pan step between last stop and home
          const lastStop = selectedPlaces[selectedPlaces.length - 1];
          const home = {
            lat: parseFloat(homeLocation.lat),
            lng: parseFloat(homeLocation.lng)
          };

          // Calculate center point between last stop and home
          const centerLat = (lastStop.lat + home.lat) / 2;
          const centerLng = (lastStop.lng + home.lng) / 2;

          // Use the same helper function as other pan steps
          const { zoom } = getBoundsBetweenPoints(
            lastStop,
            home,
            window.innerWidth,
            window.innerHeight
          );

          // Add pan step with consistent zoom level
          steps.push({
            actionType: "pan",
            lat: centerLat,
            lng: centerLng,
            zoom_lvl: zoom,
            newTransport: lastStop.transport
          });

          // Add animation step to return home using the first stop's transport
          const firstStopTransport = selectedPlaces[0].transport;
          steps.push({
            actionType: "animation",
            lat: homeLocation.lat,
            lng: homeLocation.lng,
            newTransport: firstStopTransport
          });
        }

        if (steps.length === 0) {
          alert("No steps to save!");
          return;
        }

        // ✅ 5. Save or update trip
        if (isNewTrip) {
          // Save new trip steps
          const stepRes = await apiFetch("https://hawkon.eu/api/admin/addMultipleSteps", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ tripId: currentTripId, steps, clientId }),
          });

          const stepData = await stepRes.json();
          if (!stepData.success) {
            throw new Error("Failed to save trip steps");
          }
        } else {
          // Update existing trip
          const updateRes = await apiFetch("https://hawkon.eu/api/admin/editTrip", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              clientId,
              tripId: currentTripId,
              trip: {
                name: tripName,
                initial_lat: initialLat,
                initial_lng: initialLng,
                home_lat: parseFloat(homeLocation.lat),
                home_lng: parseFloat(homeLocation.lng),
                initial_zoom_lvl: zoomLevel || 5,
                transport: mostFrequentTransport
              },
              steps
            }),
          });

          const updateData = await updateRes.json();
          if (!updateData.success) {
            throw new Error("Failed to update trip");
          }
        }

        // Log the final steps array for debugging
        console.log("📝 Final steps array:", steps);

        history.push(`/map?tripId=${currentTripId}`);

      } catch (error) {
        console.error("❌ Error saving trip:", error);

        // Rollback: Delete trip if it was newly created
        if (currentTripId && isNewTrip) {
          console.warn(`🗑️ Rolling back: Deleting trip ID ${currentTripId}`);
          try {
            await apiFetch(`https://hawkon.eu/api/admin/deleteTrip`, {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ tripId: currentTripId, clientId }),
            });
            console.log("✅ Trip successfully deleted due to failure.");
          } catch (rollbackError) {
            console.error("❌ Failed to rollback and delete trip:", rollbackError);
          }
        }

        alert("Failed to save trip.");
      } finally {
        if (mountedRef.current) {
          setIsSaving(false);
        }
      }
    } catch (error) {
      console.error("❌ Error saving trip:", error);
      alert("Failed to save trip.");
    }
  };


  const handleMapClick = async ({ lat, lng }) => {
    if (currentStep === "selectHome" && !isEditingHome) {
      setHomeLocation({ lat, lng });
      fetchClosestCity(lat, lng);
      // Save home location to localStorage
      localStorage.setItem('homeLocation', JSON.stringify({ lat, lng }));
      // setCurrentStep("selectFirstDestination");
    } else if (isEditingHome) {
      setHomeLocation({ lat, lng });
      fetchClosestCity(lat, lng);
    } else if (currentStep === "selectFirstDestination") {
      console.log("selectedTransportName", selectedTransportName);

      // Add the first destination
      setSelectedPlaces([{ lat, lng, transport: selectedTransportName }]);
      setEditingIndex(0);
      fetchClosestCity(lat, lng);
      setCurrentStep("selectNextDestination");

      // Create route from home to first destination only if not using plane or boat/ferry
      if (homeLocation) {
        const selectedTransport = transportations.find(t => t.name === selectedTransportName);
        const isAirOrSeaTravel = selectedTransport?.name.toLowerCase().includes('airplane') ||
          selectedTransport?.name.toLowerCase().includes('boat') ||
          selectedTransport?.name.toLowerCase().includes('ferry');

        if (!isAirOrSeaTravel) {
          try {
            const route = await fetchRoute(
              { lat: parseFloat(homeLocation.lat), lng: parseFloat(homeLocation.lng) },
              { lat, lng },
              clientId
            );

            if (route) {
              setRoutes([route]); // Initialize routes array with first route
            }
          } catch (error) {
            console.warn("Failed to fetch route:", error);
            // Don't set any routes if the fetch fails
          }
        }
      }
    } else if (currentStep === "selectNextDestination" && !isEditingHome) {
      if (editingIndex !== null) {
        // If we're editing an existing place, update it
        setSelectedPlaces(prevPlaces => {
          const updatedPlaces = [...prevPlaces];
          updatedPlaces[editingIndex] = {
            ...updatedPlaces[editingIndex],
            lat,
            lng,
            transport: selectedTransportName
          };
          console.log("Updated existing place:", {
            index: editingIndex,
            updatedPlaces
          });
          return updatedPlaces;
        });

        // Only fetch routes if the dot was actually moved (not just selected)
        if (editingIndex === 0) {
          // If moving the first destination, update route from home
          if (homeLocation) {
            const selectedTransport = transportations.find(t => t.name === selectedTransportName);
            const isAirOrSeaTravel = selectedTransport?.name.toLowerCase().includes('airplane') ||
              selectedTransport?.name.toLowerCase().includes('boat') ||
              selectedTransport?.name.toLowerCase().includes('ferry');

            if (!isAirOrSeaTravel) {
              try {
                const route = await fetchRoute(
                  { lat: parseFloat(homeLocation.lat), lng: parseFloat(homeLocation.lng) },
                  { lat, lng },
                  clientId
                );
                if (route) {
                  setRoutes(prevRoutes => {
                    const newRoutes = [...prevRoutes];
                    newRoutes[0] = route;
                    return newRoutes;
                  });
                }
              } catch (error) {
                console.warn("Failed to fetch route:", error);
                // Don't update routes if the fetch fails
              }
            }
          }
        } else {
          // For other destinations, update routes from previous and to next stop
          const newRoutes = [...routes];
          if (editingIndex > 0) {
            // Fetch route from previous stop
            const prevPlace = selectedPlaces[editingIndex - 1];
            const prevTransport = transportations.find(t => t.name === prevPlace.transport);
            const isAirOrSeaTravel = prevTransport?.name.toLowerCase().includes('airplane') ||
              prevTransport?.name.toLowerCase().includes('boat') ||
              prevTransport?.name.toLowerCase().includes('ferry');

            if (!isAirOrSeaTravel) {
              try {
                const prevRoute = await fetchRoute(
                  { lat: parseFloat(prevPlace.lat), lng: parseFloat(prevPlace.lng) },
                  { lat, lng },
                  clientId
                );
                if (prevRoute) {
                  newRoutes[editingIndex - 1] = prevRoute;
                }
              } catch (error) {
                console.warn("Failed to fetch route:", error);
                // Don't update routes if the fetch fails
              }
            }
          }
          if (editingIndex < selectedPlaces.length - 1) {
            // Fetch route to next stop
            const nextPlace = selectedPlaces[editingIndex + 1];
            const selectedTransport = transportations.find(t => t.name === selectedTransportName);
            const isAirOrSeaTravel = selectedTransport?.name.toLowerCase().includes('airplane') ||
              selectedTransport?.name.toLowerCase().includes('boat') ||
              selectedTransport?.name.toLowerCase().includes('ferry');

            if (!isAirOrSeaTravel) {
              try {
                const nextRoute = await fetchRoute(
                  { lat, lng },
                  { lat: parseFloat(nextPlace.lat), lng: parseFloat(nextPlace.lng) },
                  clientId
                );
                if (nextRoute) {
                  newRoutes[editingIndex] = nextRoute;
                }
              } catch (error) {
                // Don't update routes if the fetch fails
                console.warn("Failed to fetch route:", error);
              }
            }
          }
          setRoutes(newRoutes);
        }
      } else {
        // If we're adding a new place, add it to the end and set editingIndex
        setSelectedPlaces(prevPlaces => {
          // Get the transport from the last place if it exists
          const lastTransport = prevPlaces.length > 0 ? prevPlaces[prevPlaces.length - 1].transport : selectedTransportName;
          const newPlaces = [...prevPlaces, { lat, lng, transport: lastTransport }];
          return newPlaces;
        });
        setEditingIndex(selectedPlaces.length);

        // Set default transport based on last selected transport or airplane
        if (selectedPlaces.length > 0) {
          const lastPlace = selectedPlaces[selectedPlaces.length - 1];
          const lastTransport = transportations.find(t => t.name === lastPlace.transport);
          if (lastTransport) {
            setSelectedTransportName(lastTransport.name);
          } else {
            // If no last transport, default to airplane
            const airplaneTransport = transportations.find(t =>
              t.name.toLowerCase().includes("airplane") ||
              t.name.toLowerCase().includes("fly")
            );
            if (airplaneTransport) {
              setSelectedTransportName(airplaneTransport.name);
            } else if (transportations.length > 0) {
              setSelectedTransportName(transportations[0].name);
            }
          }
        } else {
          // For first stop, default to airplane or first transport
          const airplaneTransport = transportations.find(t =>
            t.name.toLowerCase().includes("airplane") ||
            t.name.toLowerCase().includes("fly")
          );
          if (airplaneTransport) {
            setSelectedTransportName(airplaneTransport.name);
          } else if (transportations.length > 0) {
            setSelectedTransportName(transportations[0].name);
          }
        }

        // If we have a previous place, fetch the route from it to the new place
        if (selectedPlaces.length > 0) {
          const prevPlace = selectedPlaces[selectedPlaces.length - 1];
          const prevTransport = transportations.find(t => t.name === prevPlace.transport);
          const isAirOrSeaTravel = prevTransport?.name.toLowerCase().includes('airplane') ||
            prevTransport?.name.toLowerCase().includes('boat') ||
            prevTransport?.name.toLowerCase().includes('ferry');

          if (!isAirOrSeaTravel) {
            try {
              const route = await fetchRoute(
                { lat: parseFloat(prevPlace.lat), lng: parseFloat(prevPlace.lng) },
                { lat, lng },
                clientId
              );

              if (route) {
                setRoutes(prevRoutes => [...prevRoutes, route]);
              }
            } catch (error) {
              console.warn("Failed to fetch route:", error);
              // Don't add route if the fetch fails
            }
          }
        }
      }
      fetchClosestCity(lat, lng);
    }
  };

  // Update dot click handler
  const handleDotClick = async (index) => {
    setEditingIndex(index);
    if (selectedPlaces.length > index) {
      const selectedPlace = selectedPlaces[index];
      if (selectedPlace.transport) {
        setSelectedTransportName(selectedPlace.transport);
      }
    }
    setCurrentStep("selectNextDestination");
  };

  // Modify the Return to Edit Stops button click handler
  const handleReturnToEditStops = () => {
    if (selectedPlaces.length === 0) return;

    // Always get the last place from selectedPlaces
    const lastPlace = selectedPlaces[selectedPlaces.length - 1];

    // Set the last place as being edited
    setEditingIndex(selectedPlaces.length - 1);
    setSelectedTransportName(lastPlace.transport || null);
    setSelectedCityName("");

    // Set currentStep back to selectNextDestination
    setCurrentStep("selectNextDestination");

    if (selectedPlaces.length === 1) {
      googleMapInstance.setCenter({ lat: selectedPlaces[0].lat, lng: selectedPlaces[0].lng });
      googleMapInstance.setZoom(4);
    } else {
      // consider this
      // const { zoom, center } = getBoundsForSteps(selectedPlaces, window.innerWidth, window.innerHeight);
      // googleMapInstance.setCenter(center);
      // googleMapInstance.setZoom(Math.min(15, zoom));
    }
  };

  // ✅ Next button handler
  const handleNext = () => {
    // Set currentStep to selectNextDestination
    setCurrentStep("selectNextDestination");
    // Set editingIndex to null to show the "Select a destination" text
    setEditingIndex(null);
    setSelectedTransportName(null);
    setSelectedCityName("");

  };

  const handleFinish = () => {
    // If we're currently editing a place, confirm it first
    if (editingIndex !== null) {
      const selectedTransport = transportations.find(t => t.name === selectedTransportName);
      const updatedPlaces = [...selectedPlaces];
      updatedPlaces[editingIndex] = {
        ...updatedPlaces[editingIndex],
        transport: selectedTransport ? selectedTransport.name : "Unknown",
      };
      setSelectedPlaces(updatedPlaces);
      setEditingIndex(null);
    }

    // Move to media upload phase
    setCurrentStep("mediaUpload");
  };

  const removeMediaFromGroup = async (stopIndex, media) => {
    const updatedGroups = { ...mediaGroups };

    // Remove media from its current group
    updatedGroups[stopIndex] = updatedGroups[stopIndex].filter(
      (mediaFile) => mediaFile !== media
    );

    // If it's a backend media, just add it to unassigned without deleting
    if (media.isBackendMedia) {
      // Add the media back to unassigned with its original path
      setUnassignedMedia((prev) => [...prev, {
        ...media,
        originalPath: media.url // Store the original path for later deletion
      }]);
    } else {
      // If it's a frontend file, add it back to unassigned
      setUnassignedMedia((prev) => [...prev, media]);
    }

    setMediaGroups(updatedGroups);
  };

  const closePreview = () => {
    setPreviewMedia(null);
  };

  const validateFiles = (files) => {
    const validFiles = [];
    const maxFileSize = 1000 * 1024 * 1024; // 110 MB

    Array.from(files).forEach((file) => {
      const isValidType = file.type.startsWith("image/") || file.type.startsWith("video/");
      const isValidSize = file.size <= maxFileSize;

      if (!isValidType) {
        alert(`❌ "${file.name}" is not a supported image or video format.`);
        return;
      }

      if (!isValidSize) {
        alert(`❌ "${file.name}" is too large! Max size is ${maxFileSize / (1024 * 1024)}MB.`);
        return;
      }

      validFiles.push(file);
    });

    return validFiles;
  };

  const handleMediaUpload = async (files) => {
    console.time('Total Media Upload');
    console.log('📥 Starting media upload process...');
    
    const validFiles = validateFiles(files);
    console.log(`✅ Validated ${validFiles.length} files out of ${files.length} total files`);
    
    // Create array to store media objects with URLs
    const mediaWithUrls = [];
    console.time('URL Creation');
    
    // Process each file
    for (let i = 0; i < validFiles.length; i++) {
      const file = validFiles[i];
      console.log(`🔄 Processing file ${i + 1}/${validFiles.length}: ${file.name}`);
      
      try {
        // Create URL for preview
        const url = URL.createObjectURL(file);
        console.log(`✅ Created URL for ${file.name}`);
        
        // Extract GPS data if available
        console.time(`GPS Extraction ${file.name}`);
        const gpsData = await extractGPSData(file);
        console.timeEnd(`GPS Extraction ${file.name}`);
        
        // Add to media array
        mediaWithUrls.push({
          file: {
            file,
            type: file.type,
            gpsData,
            isBackendMedia: false
          },
          url
        });
        console.log(`✅ Added ${file.name} to media array`);
      } catch (error) {
        console.error(`❌ Error processing ${file.name}:`, error);
      }
    }
    console.timeEnd('URL Creation');
    
    // Update state with new media
    console.time('State Updates');
    console.log('🔄 Updating state with new media...');
    setUnassignedMedia(prev => {
      console.log(`📊 Previous unassigned media count: ${prev.length}`);
      const newMedia = [...prev, ...mediaWithUrls.map(m => m.file)];
      console.log(`📊 New unassigned media count: ${newMedia.length}`);
      return newMedia;
    });
    
    // Update media URLs
    setMediaUrls(prev => {
      console.log(`📊 Previous media URLs count: ${prev.length}`);
      const newUrls = [...prev, ...mediaWithUrls.map(m => m.url)];
      console.log(`📊 New media URLs count: ${newUrls.length}`);
      return newUrls;
    });
    console.timeEnd('State Updates');
    
    console.timeEnd('Total Media Upload');
  };

  // Add a constant for hover timeout duration
  const HOVER_TIMEOUT_DURATION = 1000; // 2 seconds for desktop
  const TOUCH_TIMEOUT_DURATION = 500;  // 500ms for mobile

  // Update the handleHoverStart function to handle both hover and touch events
  const handleHoverStart = (media, idx, isAssigned = false, stopIndex = null) => {
    // For touch events, we'll use a longer delay
    const timeout = setTimeout(() => {
      setPreviewMedia(media);
    }, isMobile ? TOUCH_TIMEOUT_DURATION : HOVER_TIMEOUT_DURATION);
    setHoverTimeout(timeout);
    setHoveredThumb(isAssigned ? `assigned-${stopIndex}-${idx}` : `unassigned-${idx}`);
  };

  // Update the handleHoverEnd function to handle both hover and touch events
  const handleHoverEnd = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setHoveredThumb(null);
  };

  // Add touch event handlers
  const handleTouchStart = (e, media, idx, isAssigned = false, stopIndex = null) => {
    e.preventDefault(); // Prevent default touch behavior
    // Store the touch start time
    e.currentTarget.dataset.touchStartTime = Date.now();
    handleHoverStart(media, idx, isAssigned, stopIndex);
  };

  // Update the handleTouchEnd function to use the same timeout duration
  const handleTouchEnd = (e, media) => {
    e.preventDefault(); // Prevent default touch behavior
    const touchStartTime = parseInt(e.currentTarget.dataset.touchStartTime);
    const touchDuration = Date.now() - touchStartTime;

    // If touch duration is less than the touch timeout duration, treat it as a tap/click
    if (touchDuration < TOUCH_TIMEOUT_DURATION) {
      toggleSelectMedia(media);
    }

    handleHoverEnd();
  };

  // Add this after the styles object
  const styleSheet = document.createElement("style");
  styleSheet.innerText = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .hide-scrollbar::-webkit-scrollbar {
      display: none;  /* Chrome, Safari and Opera */
    }
  `;
  document.head.appendChild(styleSheet);


  const handleDelete = async (index) => {
    // Get the media files for this stop before removing it
    const mediaForStop = mediaGroups[index] || [];

    // Add backend media files to unassigned for later deletion
    mediaForStop.forEach(media => {
      if (media.isBackendMedia) {
        setUnassignedMedia(prev => [...prev, {
          ...media,
          originalPath: media.url // Store the original path for later deletion
        }]);
      } else {
        // If it's a frontend file, add it back to unassigned
        setUnassignedMedia(prev => [...prev, media]);
      }
    });

    // Remove the media group for this stop
    const updatedMediaGroups = { ...mediaGroups };
    delete updatedMediaGroups[index];

    // Reindex all media groups that come after the deleted index
    Object.keys(updatedMediaGroups).forEach((key) => {
      const currentIndex = parseInt(key);
      if (currentIndex > index) {
        const mediaForStop = updatedMediaGroups[key];
        updatedMediaGroups[currentIndex - 1] = mediaForStop.map(media => {
          if (media.isBackendMedia) {
            // Extract the path from the URL (remove query parameters)
            const urlWithoutParams = media.url.split('?')[0];
            const pathParts = urlWithoutParams.split('/');
            const mediaIndex = pathParts.indexOf('media');
            if (mediaIndex !== -1) {
              const currentPath = pathParts.slice(mediaIndex).join('/');
              // Create new path with updated stop index
              const newPath = currentPath.replace(
                `/${currentIndex}/`,
                `/${currentIndex - 1}/`
              );

              // Keep the original URL for display, but store the new path for the move operation
              return {
                ...media,
                originalPath: currentPath,
                relativePath: newPath
              };
            }
          }
          return media;
        });
        delete updatedMediaGroups[key];
      }
    });

    // Update media groups
    setMediaGroups(updatedMediaGroups);

    // If we're deleting the last stop, just remove it and clear routes
    if (index === selectedPlaces.length - 1) {
      setSelectedPlaces(prev => prev.filter((_, i) => i !== index));
      setRoutes(prev => prev.filter((_, i) => i !== index));
      setEditingIndex(null);
      setSelectedTransportName(null);
      setSelectedCityName("");
      return;
    }

    // If we're deleting a stop in the middle, we need to update the route between the surrounding stops
    const prevPlace = selectedPlaces[index - 1];
    const nextPlace = selectedPlaces[index + 1];

    // Remove the stop and its associated route
    setSelectedPlaces(prev => prev.filter((_, i) => i !== index));
    setRoutes(prev => prev.filter((_, i) => i !== index));

    // Fetch new route between the surrounding stops
    if (prevPlace && nextPlace) {
      const newRoute = await fetchRoute(
        { lat: parseFloat(prevPlace.lat), lng: parseFloat(prevPlace.lng) },
        { lat: parseFloat(nextPlace.lat), lng: parseFloat(nextPlace.lng) },
        clientId
      );

      if (newRoute) {
        setRoutes(prev => {
          const updatedRoutes = [...prev];
          updatedRoutes[index - 1] = newRoute;
          return updatedRoutes;
        });
      }
    }

    // Clear the editing state
    setEditingIndex(null);
    setSelectedTransportName(null);
    setSelectedCityName("");
  };

  // Add new function to handle home icon clicks
  const handleHomeIconClick = () => {
    // If we're editing a stop, confirm it first
    if (editingIndex !== null) {
      const selectedTransport = transportations.find(t => t.name === selectedTransportName);
      const updatedPlaces = [...selectedPlaces];
      updatedPlaces[editingIndex] = {
        ...updatedPlaces[editingIndex],
        transport: selectedTransport ? selectedTransport.name : "Unknown",
      };
      setSelectedPlaces(updatedPlaces);
      setEditingIndex(null);
      setSelectedTransportName(null);
      setSelectedCityName("");
    }
    setIsEditingHome(true);
  };

  const [isScrollingNeeded, setIsScrollingNeeded] = useState(false);
  const scrollContainerRef = useRef(null);

  // Add useEffect to check if scrolling is needed
  useEffect(() => {
    const checkScrollNeeded = () => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        const isNeeded = container.scrollWidth > container.clientWidth;
        setIsScrollingNeeded(isNeeded);
      }
    };

    checkScrollNeeded();
    window.addEventListener('resize', checkScrollNeeded);

    return () => window.removeEventListener('resize', checkScrollNeeded);
  }, [mediaWithUrls.length]); // Recheck when media count changes

  // Add this new component before the return statement
  const UploadProgressBar = ({ progress, status }) => {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '20px',
        borderRadius: '10px',
        zIndex: 1000,
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
      }}>
        <div style={{
          width: '100%',
          height: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '10px',
          overflow: 'hidden'
        }}>
          <div style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: '#4CAF50',
            transition: 'width 0.3s ease-in-out'
          }} />
        </div>
        <div style={{
          color: 'white',
          fontSize: '14px',
          textAlign: 'center'
        }}>
          {status}
        </div>
      </div>
    );
  };

  const handleLockToggle = async () => {
    if (!tripId) return;

    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/updateTripLock", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clientId,
          tripId,
          locked: !tripData?.trip?.locked
        }),
      });

      const data = await response.json();
      if (data.success) {
        // Update local trip data
        setTripData(prev => ({
          ...prev,
          trip: {
            ...prev.trip,
            locked: !prev.trip.locked
          }
        }));
      }
    } catch (error) {
      console.error("❌ Error toggling trip lock:", error);
    }
  };

  const isFileAlreadyAssigned = (file) => {
    // Get the original filename without path and timestamp
    const getBaseFilename = (media) => {
      if (media.isBackendMedia) {
        // For backend media, use the originalPath or url
        const path = media.originalPath || media.url;
        // Remove query parameters
        const cleanPath = path.split('?')[0];
        // Get just the filename without path
        const filenameOnly = cleanPath.split('/').pop();
        // Remove timestamp and extension
        const baseName = filenameOnly.replace(/-\d+\./, '.');
        return baseName.toLowerCase();
      } else {
        // For frontend media, use the file name directly
        return media.file.name.toLowerCase();
      }
    };

    const fileBaseName = getBaseFilename(file);

    // Check all media groups for matching filenames
    return Object.values(mediaGroups).some(group =>
      group.some(assignedMedia => {
        const assignedBaseName = getBaseFilename(assignedMedia);
        return assignedBaseName === fileBaseName;
      })
    );
  };

  // Add new function to handle GPS-based assignment
  const handleGPSAssignment = () => {
    // Get only selected media with GPS data
    const gpsMedia = selectedMedia.filter(media => media.gpsData);

    if (gpsMedia.length === 0) {
      alert("No selected images with GPS data found!");
      return;
    }

    // Create a copy of media groups to update
    const updatedGroups = { ...mediaGroups };

    // Process each selected media with GPS data
    gpsMedia.forEach(media => {
      let closestStop = null;
      let closestDistance = Infinity;

      // Find the closest stop
      selectedPlaces.forEach((place, index) => {
        const distance = calculateDistance(media.gpsData, place);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestStop = index;
        }
      });

      // If we found a closest stop, assign the media to it
      if (closestStop !== null) {
        // Initialize the group if it doesn't exist
        if (!updatedGroups[closestStop]) {
          updatedGroups[closestStop] = [];
        }

        // Add the media to the closest stop's group
        updatedGroups[closestStop].push(media);
      }
    });

    // Update media groups
    setMediaGroups(updatedGroups);

    // Remove assigned media from unassigned
    setUnassignedMedia(prev => prev.filter(media => !gpsMedia.includes(media)));

    // Clear selected media after assignment
    setSelectedMedia([]);
  };

  // // Add handleTransportChange function
  // const handleTransportChange = (index, transportName) => {
  //   setSelectedPlaces(prev => {
  //     const updated = [...prev];
  //     updated[index] = {
  //       ...updated[index],
  //       transport: transportName
  //     };
  //     return updated;
  //   });
  // };

  // // Add expandPreview function
  // const expandPreview = (url) => {
  //   setPreviewMedia(url);
  // };

  const getBaseFilename = (media) => {
    const filename = media.filename || media.name;
    return filename.split('/').pop().split('\\').pop();
  };

  // Sort unassigned media to put files with red underlines at the end
  const sortedUnassignedMedia = [...unassignedMedia].sort((a, b) => {
    const aHasRedUnderline = isFileAlreadyAssigned(a);
    const bHasRedUnderline = isFileAlreadyAssigned(b);
    
    if (aHasRedUnderline === bHasRedUnderline) return 0;
    return aHasRedUnderline ? 1 : -1; 
  });

  return (
    <div style={{ height: "100vh", width: "100vw", position: "relative", top: -8, left: -8 }}>
      {isUploading && <UploadProgressBar progress={uploadProgress} status={uploadStatus} />}
      {isSaving && (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none"
        }}>
          <div style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            pointerEvents: "none",
            minWidth: "300px"
          }}>
            <div style={{
              width: "40px",
              height: "40px",
              border: "4px solid #f3f3f3",
              borderTop: "4px solid #4CAF50",
              borderRadius: "50%",
              animation: "spin 1s linear infinite"
            }} />
            <span>Saving trip...</span>
            {isUploading && (
              <>
                <div style={{
                  width: "100%",
                  height: "20px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  marginTop: "10px"
                }}>
                  <div style={{
                    width: `${uploadProgress}%`,
                    height: "100%",
                    backgroundColor: "#4CAF50",
                    transition: "width 0.3s ease-in-out"
                  }} />
                </div>
                <div style={{
                  color: "#666",
                  fontSize: "14px",
                  textAlign: "center"
                }}>
                  {uploadStatus}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setGoogleMapInstance(map);
          setMaps(maps);
        }}
        onClick={handleMapClick}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          gestureHandling: "greedy",
          mapTypeId: "hybrid",
          zoomControl: true,
          clickableIcons: false,
        }}
      >
        {homeLocation && (
          <CustomMarker lat={homeLocation.lat} lng={homeLocation.lng} offsetX={-16}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleHomeIconClick();
              }}
              style={{
                cursor: "pointer",
                opacity: isEditingHome ? 0.5 : 1,
                transition: "opacity 0.2s"
              }}
            >
              <span style={{ fontSize: 32 }}>🏠</span>
            </div>
          </CustomMarker>
        )}

        {/* Render confirmed places */}
        {selectedPlaces.map((place, index) => (
          <CustomMarker key={index} lat={place.lat} lng={place.lng}>
            <StopMarker
              index={index}
              mediaCount={mediaGroups[index]?.length || 0}
              expandedRows={expandedRows}
              toggleRowExpansion={toggleRowExpansion}
              handleDotClick={handleDotClick}
              assignSelectedMediaToStop={assignSelectedMediaToStop}
              currentStep={currentStep}
              mediaGroups={mediaGroups}
              countMediaTypes={countMediaTypes}
              hoverTimeout={hoverTimeout}
              setHoverTimeout={setHoverTimeout}
              editingIndex={editingIndex}
              removeMediaFromGroup={removeMediaFromGroup}
              handleHoverStart={handleHoverStart}
              handleHoverEnd={handleHoverEnd}
              videoRefs={videoRefs}
              hoveredThumb={hoveredThumb}
              selectedMedia={selectedMedia}
            />
          </CustomMarker>
        ))}

        {/* {editingIndex !== null && selectedPlaces[editingIndex] && (
          <CustomMarker lat={selectedPlaces[editingIndex].lat} lng={selectedPlaces[editingIndex].lng}>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: "rgba(255, 0, 0, 0.5)",
                borderRadius: "50%",
                border: "2px solid white",
                cursor: "pointer",
                zIndex: 2
              }}
            />
          </CustomMarker>
        )} */}
      </GoogleMapReact>

      {/* Preview Overlay */}
      {previewMedia && (
        <div
          onClick={closePreview}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
            cursor: "pointer",
            opacity: 1,
            transition: "opacity 0.3s ease-in-out",
            padding: 0,
            margin: 0,
            touchAction: "none"
          }}
        >
          <div style={{
            position: "relative",
            maxWidth: "90vw",
            maxHeight: "90vh",
            width: "auto",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            padding: 0,
            touchAction: "none"
          }}>
            {previewMedia.type.startsWith("video") ? (
              <video
                src={previewMedia.isBackendMedia ? previewMedia.url : URL.createObjectURL(previewMedia.file)}
                controls
                autoPlay
                style={{
                  maxWidth: "100%",
                  maxHeight: "90%",
                  width: "auto",
                  height: "90vh",
                  objectFit: "contain",
                  touchAction: "none"
                }}
              />
            ) : (
              <img
                src={previewMedia.isBackendMedia ? previewMedia.url : URL.createObjectURL(previewMedia.file)}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "90%",
                  width: "auto",
                  height: "90vh",
                  objectFit: "contain",
                  touchAction: "none",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none"
                }}
              />
            )}
          </div>
          <div style={{
            position: "absolute",
            bottom: "20px",
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: "14px",
            fontFamily: "Arial, sans-serif"
          }}>
            Click anywhere to close
          </div>
        </div>
      )}

      {/* Floating Control Panel */}
      <div style={styles.overlayBox}>
        {/* Select Home Step */}
        {currentStep === "selectHome" && !homeConfirmed && (
          <>
            <h2>Name Your Trip</h2>

            {/* Trip Name Input */}
            <div style={{ marginBottom: 10, width: "100%" }}>
              <label htmlFor="tripName">Trip Name:</label>
              <input
                id="tripName"
                type="text"
                value={tripName}
                onChange={(e) => setTripName(e.target.value)}
                placeholder="Enter trip name..."
                ref={tripNameInputRef}
                style={{
                  width: "calc(100% - 20px)",
                  padding: "8px 10px",
                  marginTop: "5px",
                  marginBottom: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            {homeLocation && selectedCityName && (
              <div style={{ marginBottom: 10 }}>
                <strong>Selected Location:</strong> {selectedCityName}
              </div>
            )}

            {tripName.trim() !== "" && !homeLocation && (
              <h3>Click on the map to select your Home</h3>
            )}

            {homeLocation && (
              <button
                onClick={() => {
                  setHomeConfirmed(true);
                  setCurrentStep("selectFirstDestination");
                }}
                style={{
                  ...styles.button,
                  marginTop: "10px",
                }}
              >
                ➡️ Continue
              </button>
            )}
          </>
        )}

        {/* Display Trip Name with Edit Button */}
        {homeConfirmed && currentStep !== "mediaUpload" && !isEditingHome && (
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "15px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            padding: "10px",
            borderRadius: "5px"
          }}>
            {isEditingTripName ? (
              <>
                <input
                  type="text"
                  value={tripName}
                  onChange={(e) => setTripName(e.target.value)}
                  style={{
                    flex: 1,
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                    color: "black"
                  }}
                />
                <button
                  onClick={() => setIsEditingTripName(false)}
                  disabled={!tripName.trim()}
                  style={{
                    ...styles.buttonSecondary,
                    padding: "8px 15px",
                    fontSize: "14px",
                    opacity: !tripName.trim() ? 0.5 : 1,
                    cursor: !tripName.trim() ? "not-allowed" : "pointer"
                  }}
                >
                  ✓
                </button>
                <button
                  onClick={() => {
                    setTripName(tripData?.trip?.name || "");
                    setIsEditingTripName(false);
                  }}
                  style={{
                    ...styles.buttonSecondary,
                    padding: "8px 15px",
                    fontSize: "14px",
                    backgroundColor: "#ff4444"
                  }}
                >
                  ✕
                </button>
              </>
            ) : (
              <>
                <h2 style={{ margin: 0, flex: 1 }}>{tripName || "Untitled Trip"}</h2>
                <button
                  onClick={() => setIsEditingTripName(true)}
                  style={{
                    ...styles.buttonSecondary,
                    padding: "8px 15px",
                    fontSize: "14px"
                  }}
                >
                  ✎
                </button>
                {isAdmin && tripId && (
                  <button
                    onClick={handleLockToggle}
                    style={{
                      ...styles.buttonSecondary,
                      padding: "8px 15px",
                      fontSize: "14px",
                      backgroundColor: tripData?.trip?.locked ? "#ff4444" : "#4CAF50"
                    }}
                  >
                    {tripData?.trip?.locked ? "🔒" : "🔓"}
                  </button>
                )}
              </>
            )}
          </div>
        )}

        {/* Home Edit Mode UI */}
        {isEditingHome && (
          <>
            <h2>Click on the map to select your home</h2>
            <button
              onClick={() => {
                setIsEditingHome(false);
                // Get the last place from selectedPlaces
                if (selectedPlaces.length > 0) {
                  const lastPlace = selectedPlaces[selectedPlaces.length - 1];
                  setEditingIndex(selectedPlaces.length - 1);
                  setSelectedTransportName(transportations.find(t => t.name === lastPlace.transport)?.name || null);
                  fetchClosestCity(lastPlace.lat, lastPlace.lng);
                }
              }}
              style={{
                ...styles.button,
                marginTop: "10px",
              }}
            >
              ➡️ Continue
            </button>
          </>
        )}

        {/* Rest of the control panel content */}
        {!isEditingHome && currentStep === "mediaUpload" && (
          <div style={{ display: "flex", gap: 20, alignItems: "flex-start", backgroundColor: "transparent", flexDirection: "column" }}>
            {/* Upload Box */}
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-start",
                border: isDragging ? "2px dashed lime" : "2px dashed gray",
                padding: 20,
                borderRadius: 10,
                marginBottom: 0,
                backgroundColor: isDragging ? "rgba(0,255,0,0.1)" : "transparent",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                accept="image/*,video/*"
                multiple
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleMediaUpload(e.target.files)}
              />

              <h2 style={{
                whiteSpace: "nowrap",
                marginTop: "0",
                marginBottom: "10px"
              }}>📸 Upload Images and Videos</h2>

              {!isMobile && (
                <div style={{
                  marginBottom: "20px",
                  color: "rgba(255, 255, 255, 0.8)",
                  fontSize: "14px",
                  textAlign: "center"
                }}>
                  {isDragging ? "Release to upload files!" : "Drag & drop files here"}
                </div>
              )}

              <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "0" }}>
                <button
                  style={{
                    ...styles.buttonSecondary,
                    width: "100%",
                    justifyContent: "center"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    fileInputRef.current.click();
                  }}
                >
                  📂 Browse Files
                </button>

                {/* Return to Edit Stops Button */}
                <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                  <button
                    onClick={handleReturnToEditStops}
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      fontSize: "16px"
                    }}
                  >
                    Return to Edit Stops
                  </button>
                </div>
                {Object.values(mediaGroups).reduce((total, group) => total + group.length, 0) + unassignedMedia.length > 20 && (
                  <button
                    onClick={toggleAllRows}
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      fontSize: "16px"
                    }}
                  >
                    {allExpanded ? "Hide All" : "Expand All"}
                  </button>
                )}

                <button
                  onClick={saveTrip}
                  disabled={isSaving}
                  style={{
                    ...styles.buttonSecondary,
                    backgroundColor: isSaving ? "#cccccc" : "#4CAF50",
                    cursor: isSaving ? "not-allowed" : "pointer",
                    position: "relative",
                    zIndex: 1001,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px"
                  }}
                >
                  {isSaving ? (
                    <>
                      <span style={{
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                        border: "2px solid #ffffff",
                        borderTop: "2px solid transparent",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite",
                        marginRight: "8px",
                        verticalAlign: "middle"
                      }} />
                      Saving...
                    </>
                  ) : (
                    "💾 Save Trip"
                  )}
                </button>
              </div>
            </div>
            {unassignedMedia.length > 0 && !hasPerformedFirstAssignment && (
              <div style={{
                width: "100%",
                fontSize: "14px",
                textAlign: "center",
                opacity: 0.8
              }}>
                Click on the images below <br />then a red dot to connect images to a destination
              </div>
            )}
          </div>
        )}

        {/* Select Destinations */}
        {homeConfirmed && currentStep !== "mediaUpload" && !isEditingHome && (
          <>
            <h2>{editingIndex === null && "Select a destination on the map"}</h2>

            {editingIndex !== null && (
              <>
                {selectedPlaces[editingIndex].lat !== null && selectedCityName && selectedCityName !== "Unknown location" && (
                  <div style={{ marginBottom: 10 }}>
                    <strong>Location:</strong> {selectedCityName}
                  </div>
                )}

                {selectedPlaces[editingIndex].lat !== null && (
                  <>
                    <TransportDropdown
                      transportations={transportations}
                      selectedTransportName={selectedTransportName}
                      setSelectedTransportName={setSelectedTransportName}
                      editingIndex={editingIndex}
                      selectedPlaces={selectedPlaces}
                      setSelectedPlaces={setSelectedPlaces}
                    />

                    <div style={{ marginTop: 15, display: "flex", gap: "10px", alignItems: "center" }}>
                      <button
                        onClick={() => handleDelete(editingIndex)}
                        style={{
                          ...styles.buttonSecondary,
                          backgroundColor: "#ff4444",
                          padding: "10px 20px",
                          fontSize: "16px"
                        }}
                      >
                        🗑️ Delete Stop
                      </button>
                      <button style={{
                        ...styles.buttonSecondary,
                        backgroundColor: "#2196F3",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        padding: "10px 20px",
                        fontSize: "16px"
                      }} onClick={handleNext}>
                        ➕ New Stop
                      </button>

                      {/* reordering controls */}
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        height: "100%",
                        justifyContent: "center"
                      }}>
                        <button
                          onClick={() => {
                            if (editingIndex < selectedPlaces.length - 1) {
                              const updatedPlaces = [...selectedPlaces];
                              [updatedPlaces[editingIndex], updatedPlaces[editingIndex + 1]] = [updatedPlaces[editingIndex + 1], updatedPlaces[editingIndex]];
                              setSelectedPlaces(updatedPlaces);

                              // Track image moves for both stops
                              const moves = [];
                              const currentStopMedia = mediaGroups[editingIndex] || [];
                              const nextStopMedia = mediaGroups[editingIndex + 1] || [];

                              // Move images from current stop to next stop
                              currentStopMedia.forEach(media => {
                                if (media.isBackendMedia) {
                                  const urlParts = media.url.split('?')[0].split('/');
                                  const mediaIndex = urlParts.indexOf('media');
                                  if (mediaIndex !== -1) {
                                    const currentPath = urlParts.slice(mediaIndex).join('/');
                                    const newPath = currentPath.replace(
                                      `/${editingIndex}/`,
                                      `/${editingIndex + 1}/`
                                    );
                                    console.log(`🔄 Moving image from stop ${editingIndex} to ${editingIndex + 1}:`, {
                                      from: currentPath,
                                      to: newPath
                                    });
                                    moves.push({
                                      from: currentPath,
                                      to: newPath
                                    });
                                  }
                                }
                              });

                              // Move images from next stop to current stop
                              nextStopMedia.forEach(media => {
                                if (media.isBackendMedia) {
                                  const urlParts = media.url.split('?')[0].split('/');
                                  const mediaIndex = urlParts.indexOf('media');
                                  if (mediaIndex !== -1) {
                                    const currentPath = urlParts.slice(mediaIndex).join('/');
                                    const newPath = currentPath.replace(
                                      `/${editingIndex + 1}/`,
                                      `/${editingIndex}/`
                                    );
                                    console.log(`🔄 Moving image from stop ${editingIndex + 1} to ${editingIndex}:`, {
                                      from: currentPath,
                                      to: newPath
                                    });
                                    moves.push({
                                      from: currentPath,
                                      to: newPath
                                    });
                                  }
                                }
                              });

                              console.log('📦 All moves for this reorder:', moves);
                              console.log('📦 Current pending moves:', pendingImageMoves);

                              // Update media groups
                              const updatedGroups = { ...mediaGroups };
                              // Ensure both indices exist with empty arrays if they don't
                              if (!updatedGroups[editingIndex]) updatedGroups[editingIndex] = [];
                              if (!updatedGroups[editingIndex + 1]) updatedGroups[editingIndex + 1] = [];

                              const temp = updatedGroups[editingIndex];
                              updatedGroups[editingIndex] = updatedGroups[editingIndex + 1];
                              updatedGroups[editingIndex + 1] = temp;
                              setMediaGroups(updatedGroups);

                              // Add moves to pending moves
                              setPendingImageMoves(prev => [...prev, ...moves]);

                              // Update the editingIndex to reflect the new position
                              setEditingIndex(editingIndex + 1);
                            }
                          }}
                          disabled={selectedPlaces.length <= 1 || editingIndex === selectedPlaces.length - 1}
                          style={{
                            ...styles.buttonSecondary,
                            padding: "4px 8px",
                            opacity: selectedPlaces.length <= 1 || editingIndex === selectedPlaces.length - 1 ? 0.5 : 1,
                            cursor: selectedPlaces.length <= 1 || editingIndex === selectedPlaces.length - 1 ? "not-allowed" : "pointer",
                            fontSize: "14px",
                            height: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          ⬆️
                        </button>
                        <button
                          onClick={() => {
                            if (editingIndex > 0) {
                              const updatedPlaces = [...selectedPlaces];
                              [updatedPlaces[editingIndex], updatedPlaces[editingIndex - 1]] = [updatedPlaces[editingIndex - 1], updatedPlaces[editingIndex]];
                              setSelectedPlaces(updatedPlaces);

                              // Track image moves for both stops
                              const moves = [];
                              const currentStopMedia = mediaGroups[editingIndex] || [];
                              const prevStopMedia = mediaGroups[editingIndex - 1] || [];

                              // Move images from current stop to previous stop
                              currentStopMedia.forEach(media => {
                                if (media.isBackendMedia) {
                                  const urlParts = media.url.split('?')[0].split('/');
                                  const mediaIndex = urlParts.indexOf('media');
                                  if (mediaIndex !== -1) {
                                    const currentPath = urlParts.slice(mediaIndex).join('/');
                                    const newPath = currentPath.replace(
                                      `/${editingIndex}/`,
                                      `/${editingIndex - 1}/`
                                    );
                                    console.log(`🔄 Moving image from stop ${editingIndex} to ${editingIndex - 1}:`, {
                                      from: currentPath,
                                      to: newPath
                                    });
                                    moves.push({
                                      from: currentPath,
                                      to: newPath
                                    });
                                  }
                                }
                              });

                              // Move images from previous stop to current stop
                              prevStopMedia.forEach(media => {
                                if (media.isBackendMedia) {
                                  const urlParts = media.url.split('?')[0].split('/');
                                  const mediaIndex = urlParts.indexOf('media');
                                  if (mediaIndex !== -1) {
                                    const currentPath = urlParts.slice(mediaIndex).join('/');
                                    const newPath = currentPath.replace(
                                      `/${editingIndex - 1}/`,
                                      `/${editingIndex}/`
                                    );
                                    console.log(`🔄 Moving image from stop ${editingIndex - 1} to ${editingIndex}:`, {
                                      from: currentPath,
                                      to: newPath
                                    });
                                    moves.push({
                                      from: currentPath,
                                      to: newPath
                                    });
                                  }
                                }
                              });

                              console.log('📦 All moves for this reorder:', moves);
                              console.log('📦 Current pending moves:', pendingImageMoves);

                              // Update media groups
                              const updatedGroups = { ...mediaGroups };
                              // Ensure both indices exist with empty arrays if they don't
                              if (!updatedGroups[editingIndex]) updatedGroups[editingIndex] = [];
                              if (!updatedGroups[editingIndex - 1]) updatedGroups[editingIndex - 1] = [];

                              const temp = updatedGroups[editingIndex];
                              updatedGroups[editingIndex] = updatedGroups[editingIndex - 1];
                              updatedGroups[editingIndex - 1] = temp;
                              setMediaGroups(updatedGroups);

                              // Add moves to pending moves
                              setPendingImageMoves(prev => [...prev, ...moves]);

                              // Update the editingIndex to reflect the new position
                              setEditingIndex(editingIndex - 1);
                            }
                          }}
                          disabled={selectedPlaces.length <= 1 || editingIndex === 0}
                          style={{
                            ...styles.buttonSecondary,
                            padding: "4px 8px",
                            opacity: selectedPlaces.length <= 1 || editingIndex === 0 ? 0.5 : 1,
                            cursor: selectedPlaces.length <= 1 || editingIndex === 0 ? "not-allowed" : "pointer",
                            fontSize: "14px",
                            height: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          ⬇️
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* Show "Go to image upload" button when there's at least one stop */}
            {selectedPlaces.length > 0 && (
              <button
                onClick={handleFinish}
                style={{
                  ...styles.buttonSecondary,
                  marginTop: 15,
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                📸 Go to image upload
              </button>
            )}
          </>
        )}
        {/* Unassigned Media */}
        {sortedUnassignedMedia.length > 0 && currentStep === "mediaUpload" && (
          <div style={{
            position: "absolute",
            top: (() => {
              const baseValue = 305;
              const noFirstAssignment = !hasPerformedFirstAssignment ? 55 : 0;
              const totalMediaCount = Object.values(mediaGroups).reduce((sum, group) => sum + group.length, 0);
              const manyImages = totalMediaCount > 20 ? 50 : 0;
              return baseValue + noFirstAssignment + manyImages;
            })(),
            left: 0,
            right: 0,
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}>
            <div
              ref={scrollContainerRef}
              className="hide-scrollbar"
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
                overflowY: "visible",
                whiteSpace: "nowrap",
                paddingBottom: "5px",
                paddingLeft: "5px",
                paddingRight: "5px"
              }}
            >
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                padding: "5px 0 0 0"
              }}>
                {sortedUnassignedMedia.map((file, idx) => {
                  const isSelected = selectedMedia.includes(file);
                  const isHovered = hoveredThumb === `unassigned-${idx}`;
                  const isAssigned = isFileAlreadyAssigned(file);
                  const url = file.isBackendMedia ? file.url : URL.createObjectURL(file.file);

                  return (
                    <div
                      key={idx}
                      data-media-id={file.isBackendMedia ? file.originalPath || file.url : file.file.name}
                      onClick={() => toggleSelectMedia(file)}
                      onMouseEnter={() => handleHoverStart(file, idx)}
                      onMouseLeave={handleHoverEnd}
                      onTouchStart={(e) => handleTouchStart(e, file, idx)}
                      onTouchEnd={(e) => handleTouchEnd(e, file)}
                      style={{
                        ...getThumbnailWrapperStyle(isSelected, isHovered),
                        touchAction: "none",
                        flexShrink: 0,
                        position: "relative"
                      }}
                    >
                      {file.gpsData && (
                        <div style={{
                          position: "absolute",
                          top: "4px",
                          left: "4px",
                          width: "12px",
                          height: "12px",
                          backgroundColor: "#2196F3",
                          borderRadius: "50%",
                          zIndex: 2,
                          border: "2px solid white"
                        }} />
                      )}
                      {isAssigned && (
                        <div style={{
                          position: "absolute",
                          bottom: "-2px",
                          left: "0",
                          right: "0",
                          height: "2px",
                          backgroundColor: "rgba(255, 0, 0, 0.5)",
                          zIndex: 3
                        }} />
                      )}
                      <div style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden"
                      }}>
                        {file.type.startsWith("video") ? (
                          <video
                            src={url}
                            muted
                            loop
                            autoPlay
                            playsInline
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              pointerEvents: "none",
                            }}
                          />
                        ) : (
                          <img
                            src={url}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        <div style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: "rgba(0,0,0,0.5)",
                          clipPath: hoveredThumb === `unassigned-${idx}`
                            ? "circle(80% at center)"
                            : "circle(0% at center)",
                          transition: "clip-path 1s ease-in-out",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }} />
                        {isSelected && (
                          <div style={{
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#4CAF50",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontSize: "12px",
                            zIndex: 2
                          }}>
                            ✓
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Only show slider if scrolling is needed */}
            {isScrollingNeeded && (
              <div
                className="hide-scrollbar"
                style={{
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 10px"
                }}
              >
                <input
                  type="range"
                  min="0"
                  max="100"
                  defaultValue="0"
                  onChange={(e) => {
                    const scrollContainer = e.currentTarget.parentElement.previousElementSibling;
                    const scrollPercentage = e.target.value / 100;
                    const scrollAmount = scrollPercentage * (scrollContainer.scrollWidth - scrollContainer.clientWidth);
                    scrollContainer.scrollTo({
                      left: scrollAmount,
                      behavior: 'smooth'
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "4px",
                    WebkitAppearance: "none",
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "2px",
                    outline: "none",
                    cursor: "pointer"
                  }}
                />
              </div>
            )}

            {/* Button Container */}
            <div style={{
              display: "flex",
              gap: "5px",
              width: "100%",
              marginBottom: "10px"
            }}>
              {/* Select All Button */}
              <button
                onClick={() => {
                  // Check if all unassigned media is currently selected
                  const allSelected = unassignedMedia.length > 0 &&
                    selectedMedia.length === unassignedMedia.length &&
                    unassignedMedia.every(media => selectedMedia.includes(media));

                  if (allSelected) {
                    // Deselect all
                    setSelectedMedia([]);
                  } else {
                    // Select all
                    setSelectedMedia([...unassignedMedia]);
                  }
                }}
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "center",
                  flex: 1
                }}
              >
                {unassignedMedia.length > 0 &&
                  selectedMedia.length === unassignedMedia.length &&
                  unassignedMedia.every(media => selectedMedia.includes(media))
                  ? "❌ Deselect All"
                  : "✨ Select All"
                }
              </button>

              {selectedMedia.some(media => media.gpsData) && (
                <button
                  onClick={handleGPSAssignment}
                  style={{
                    backgroundColor: "#2196F3",
                    color: "white",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",
                    flex: 1
                  }}
                >
                  📍 Assign by GPS
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  overlayBox: {
    position: "absolute",
    top: 20,
    left: 20,
    right: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    padding: 20,
    borderRadius: 10,
    zIndex: 100,
    maxWidth: "600px",
    marginRight: 20,
  },
  browseButton: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    cursor: "pointer",
    flexShrink: 0, // keeps size consistent
  },
  button: {
    padding: "10px 20px",
    background: "#2196F3",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  buttonSecondary: {
    padding: "10px 20px",
    background: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  select: {
    marginTop: 5,
    width: "100%",
    padding: "8px",
  },
  thumbnailWrapper: {
    position: "relative",
    width: 80,
    height: 80,
    cursor: "pointer",
    overflow: "visible",
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    transition: "transform 0.2s ease",
  },
  unassignedMediaContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    bottom: -90,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0)",
    padding: 0,
    zIndex: 100,
    overflowX: "auto",
    overflowY: "hidden",
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none", // IE and Edge
    "&::-webkit-scrollbar": { // Chrome, Safari, Opera
      display: "none"
    }
  },
  saveButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "15px 30px",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    zIndex: 3000,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "&:disabled": {
      backgroundColor: "#cccccc",
      cursor: "not-allowed",
    }
  },
  spinner: {
    width: "20px",
    height: "20px",
    border: "3px solid #ffffff",
    borderTop: "3px solid transparent",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  previewOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999999,
    cursor: "pointer",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  previewContent: {
    maxWidth: "90vw",
    maxHeight: "90vh",
    width: "auto",
    height: "auto",
    objectFit: "contain",
    transform: "scale(0.95)",
    transition: "transform 0.3s ease-in-out",
  },
  previewVisible: {
    opacity: 1,
  },
  previewContentVisible: {
    transform: "scale(1)",
  },
};
