import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainPage from './components/MainPage';
import MapPage from './components/MapPage';
import AdminPage from './components/AdminPage';
import TripPlannerPage from './components/TripPlannerPage';
import TransportationAdminPage from './components/TransportationAdminPage';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route path="/map" component={MapPage} />
        <Route path="/admin/transportation" component={TransportationAdminPage} />
        <Route path="/admin" component={AdminPage} />
        <Route path="/trip-planner" component={TripPlannerPage} />
      </Switch>
    </Router>
  );
}