// Global error handler for API responses
export const handleApiResponse = (response) => {
  if (response.status === 403) {
    // Use window.location for navigation since we can't use useHistory outside of components
    window.location.href = '/';
  }
  return response;
};

export const fetchPresignedUrl = async (filename, clientId) => {
  console.log("Fetching Presigned URL with:", { filename, clientId });

  if (!clientId) {
    console.error("🚨 clientId is missing!");
    return null;
  }

  const response = await fetch(`https://hawkon.eu/presigned/?filename=${filename}&clientId=${clientId}`);
  handleApiResponse(response);

  if (!response.ok) {
    console.error("Error fetching pre-signed URL:", response.statusText);
    return null;
  }

  const data = await response.json();
  return data.url;
};

// Helper function to wrap fetch calls with error handling
export const apiFetch = async (url, options = {}) => {
  const response = await fetch(url, options);
  handleApiResponse(response);
  return response;
};