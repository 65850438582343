import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAuthenticated } from "../redux/actions/isAuthenticated";
import ImageDropZone from '../utils/imageHelper';
import { useHistory } from 'react-router-dom';
import { apiFetch } from "../utils/api";

// import * as exifr from 'exifr';

export default function AdminPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clientId);
  const [isAdmin, setIsAdmin] = useState(false);

  const [tripData, setTripData] = useState({
    name: "",
    initial_lat: "",
    initial_lng: "",
    initial_zoom_lvl: 5,
    transport: "",
  });

  const [trips, setTrips] = useState([]);
  const [tripId, setTripId] = useState("");
  const [steps, setSteps] = useState([]);
  const [showManageSteps, setShowManageSteps] = useState(false);
  const [isReordering, setIsReordering] = useState(false);

  const [stepData, setStepData] = useState(getEmptyStep());
  const [tripMode, setTripMode] = useState("existing"); // "existing" or "create"
  const [editingStepId, setEditingStepId] = useState(null);

  // =========== USE EFFECTS ===========
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await apiFetch("https://hawkon.eu/api/checkSession", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ clientId }),
        });
        const data = await response.json();
        if (data.authenticated) {
          dispatch(setAuthenticated(true));
          if (data.trips) setTrips(data.trips);
          setIsAdmin(data.isAdmin);
        } else {
          dispatch(setAuthenticated(false));
        }
      } catch (error) {
        console.error("❌ Error checking session:", error);
      }
    };
    checkSession();
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!tripId) return;
    const fetchSteps = async () => {
      try {
        const response = await apiFetch(`https://hawkon.eu/api/getTripPath?tripId=${tripId}&clientId=${clientId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        
        const data = await response.json();
        setSteps(data.pathSteps);
      } catch (error) {
        console.error("❌ Error fetching steps:", error);
      }
    };
    fetchSteps();
  }, [tripId, clientId]);

  // =========== FUNCTIONS ===========
  function getEmptyStep() {
    return {
      actionType: "",
      lat: "",
      lng: "",
      zoom_lvl: getLastZoomLevel() || 6,
      newTransport: "",
      directionsFilename: "",
      galleryName: "",
      images: "",
    };
  }

  function getLastZoomLevel() {
    const lastZoom = steps
      .slice()
      .reverse()
      .find((step) => step.zoom_lvl !== null && step.zoom_lvl !== undefined);
    return lastZoom ? lastZoom.zoom_lvl : 6;
  }

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter((prev) => ({ ...prev, [name]: value }));
  };

  const moveStep = (index, direction) => {
    const newSteps = [...steps];
    const targetIndex = index + direction;

    if (targetIndex < 0 || targetIndex >= steps.length) return; // prevent out of bounds

    const [movedStep] = newSteps.splice(index, 1);
    newSteps.splice(targetIndex, 0, movedStep);
    setSteps(newSteps);
  };

  const reorderSteps = async () => {
    console.log("dsada");

    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/reorderSteps", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tripId, steps, clientId }),
      });

      if (response.ok) {
        alert("Steps reordered!");
        setIsReordering(false);
      }
    } catch (error) {
      console.error("❌ Error reordering steps:", error);
    }
  };

  const handleEditStep = (step) => {
    setStepData({
      actionType: step.action_type,
      lat: step.lat,
      lng: step.lng,
      zoom_lvl: step.zoom_lvl,
      newTransport: step.new_transport,
      directionsFilename: step.directions_filename,
      galleryName: step.gallery_name,
      images: step.images?.join(",") || "",
    });
    setEditingStepId(step.id);
  };

  // const handleFileDrop = (e) => {
  //   e.preventDefault();
  //   const files = Array.from(e.dataTransfer.files);
  //   console.log("📂 Dropped files:", files);

  //   const filenames = files.map((file) => file.name);
  //   setStepData((prev) => ({
  //     ...prev,
  //     images: filenames.join(","),
  //   }));
  // };

  // const handleFileDrop = async (e) => {
  //   e.preventDefault();
  //   const files = Array.from(e.dataTransfer.files);

  //   for (const file of files) {
  //     const gps = await exifr.gps(file);
  //     console.log('📍 GPS:', gps);
  //   }
  // };

  // const handleDragOver = (e) => e.preventDefault();

  const createTrip = async (tripData) => {
    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/addTrip", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(tripData, clientId),
      });

      if (!response.ok) {
        alert("❌ Failed to create trip.");
        return;
      }

      const data = await response.json();

      if (!data.success) {
        alert("❌ Trip creation unsuccessful.");
        return;
      }

      if (!Array.isArray(data.trips)) {
        console.error("❌ Response does not contain trips array:", data);
        alert("❌ No trips returned from server.");
        return;
      }

      alert("✅ Trip created successfully!");

      setTrips(data.trips);                // Replace all trips with updated list
      setTripMode("existing");

      // Select the latest trip (optional, adjust if needed)
      const latestTrip = data.trips[data.trips.length - 1];
      setTripId(latestTrip.id);

      // Reset form
      setTripData({
        name: "",
        initial_lat: "",
        initial_lng: "",
        initial_zoom_lvl: 5,
        transport: "",
      });

    } catch (error) {
      console.error("❌ Error creating trip:", error);
      alert("❌ An unexpected error occurred.");
    }
  };

  const addOrUpdateStep = async () => {
    if (!tripId) {
      alert("Please select a trip first.");
      return;
    }

    if (
      stepData.actionType === "show_images" &&
      (!stepData.galleryName || stepData.galleryName.trim() === "")
    ) {
      alert("Gallery name is required for image steps!");
      return;
    }

    const payload = {
      ...stepData,
      tripId,
      images: stepData.images.split(",").map((img) => img.trim()).filter(Boolean),
    };

    try {
      let response;
      if (editingStepId) {
        response = await apiFetch("https://hawkon.eu/api/admin/updateTripStep", {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ stepId: editingStepId, ...payload, clientId }),
        });

        if (response.ok) {
          console.log("✅ Step updated!");
        }
      } else {
        response = await apiFetch("https://hawkon.eu/api/admin/addTripStep", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({payload, clientId}),
        });

        if (response.ok) {
          console.log("✅ Step added!");
        }
      }

      if (response.ok) {
        // 🔥 Fetch latest steps from backend after add/update
        const stepsResponse = await apiFetch(`https://hawkon.eu/api/getTripPath?tripId=${tripId}&clientId=${clientId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const stepsData = await stepsResponse.json();
        setSteps(stepsData.pathSteps);

        setEditingStepId(null);
        setStepData(getEmptyStep());
      }
    } catch (error) {
      console.error("❌ Error adding/updating step:", error);
    }
  };

  const deleteStep = async (stepId) => {
    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/deleteStep", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ stepId, clientId }),
      });
      if (response.ok) {
        setSteps((prev) => prev.filter((step) => step.id !== stepId));
      }
    } catch (error) {
      console.error("❌ Error deleting step:", error);
    }
  };


  const deleteTrip = async () => {
    if (!tripId) {
      alert("Please select a trip to delete.");
      return;
    }

    try {
      const response = await apiFetch(`https://hawkon.eu/api/admin/deleteTrip`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tripId: parseInt(tripId), clientId }),
      });

      const data = await response.json();
      
      if (response.status === 403) {
        alert("❌ You don't have permission to delete trips.");
        return;
      }
      
      if (data.success) {
        alert("✅ Trip deleted successfully!");
        // Refresh trips
        setTrips((prevTrips) => prevTrips.filter((trip) => trip.id !== parseInt(tripId)));
        setTripId("");        // Reset selected trip
        setSteps([]);         // Clear steps
        setShowManageSteps(false); // Hide manage steps panel
      } else {
        alert("❌ Failed to delete trip.");
      }
    } catch (error) {
      console.error("❌ Error deleting trip:", error);
      alert("❌ An unexpected error occurred.");
    }
  };

  // =========== JSX ===========
  return (
    <div style={{ padding: 20, marginBottom: 50 }}>
      <h1>Admin Page</h1>


      <div style={{ marginBottom: "20px" }}>
        <label>
          <input
            type="radio"
            name="tripMode"
            value="existing"
            checked={tripMode === "existing"}
            onChange={() => setTripMode("existing")}
          />
          Select Existing Trip
        </label>

        <label style={{ marginLeft: "20px" }}>
          <input
            type="radio"
            name="tripMode"
            value="create"
            checked={tripMode === "create"}
            onChange={() => setTripMode("create")}
          />
          Create New Trip
        </label>
      </div>


      {tripMode === "existing" ? (
        <>
          <h2>Select a Trip</h2>
          <select
            value={tripId}
            onChange={(e) => {
              setTripId(e.target.value);
              setStepData(getEmptyStep());
            }}
          >
            <option value="">-- Choose a Trip --</option>
            {trips?.map((trip) => (
              <option key={trip.id} value={trip.id}>
                {trip.name}
              </option>
            ))}
          </select>

          {/* Show Delete Trip button only if a trip is selected AND user is admin */}
          {tripId !== "" && isAdmin && (
            <button
              onClick={deleteTrip}
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '8px 16px',
                marginTop: '10px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              🗑️ Delete Trip
            </button>
          )}
        </>
      ) : (
        <>
          <h2>Create New Trip</h2>
          {Object.keys(tripData).map((field) => (
            <div key={field} style={{ marginBottom: "10px" }}>
              <input
                type={field.includes("lat") || field.includes("lng") || field.includes("zoom") ? "number" : "text"}
                name={field}
                placeholder={field.replace(/_/g, " ")}
                value={tripData[field]}
                onChange={(e) => setTripData({ ...tripData, [field]: e.target.value })}
              />
            </div>
          ))}
          <button onClick={() => createTrip(tripData)}>➕ Create Trip</button>
        </>
      )}

      {/* === Manage Steps Toggle === */}
      {tripId && (
        <>
          <div style={{ marginTop: 20 }}>
            <button onClick={() => setShowManageSteps(!showManageSteps)}>
              {showManageSteps ? "Hide Manage Steps" : "Manage Steps"}
            </button>
          </div>

          {/* === Manage Steps === */}
          {showManageSteps && (
            <div style={{ marginTop: 20, border: "1px solid #ddd", padding: 10, borderRadius: 8 }}>
              <h2>Manage Steps</h2>
              {!isReordering ? (
                <button onClick={() => setIsReordering(true)}>
                  🔄 Reorder Steps
                </button>
              ) : (
                <>
                  <button onClick={reorderSteps} style={{ marginLeft: 10 }}>
                    💾 Save Order
                  </button>
                  <button
                    onClick={() => setIsReordering(false)}
                    style={{ marginLeft: 10 }}
                  >
                    ❌ Cancel
                  </button>
                </>
              )}

              <ul style={{ listStyleType: "none", padding: 0, marginTop: 10 }}>
                {steps.map((step, index) => (
                  <li
                    key={step.id}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: 8,
                      padding: 10,
                      marginBottom: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <strong>{step.action_type?.toUpperCase() || "UNKNOWN"}</strong>

                      {step.action_type === "pan" || step.action_type === "zoom" ? (
                        <div>Lat: {step.lat} | Lng: {step.lng} | Zoom: {step.zoom_lvl}</div>
                      ) : step.action_type === "transport" ? (
                        <div>New Transport: {step.new_transport}</div>
                      ) : step.action_type === "animation" ? (
                        <div>Animation File: {step.directionsFilename}</div>
                      ) : step.action_type === "show_images" ? (
                        <div>
                          Gallery: {step.galleryName} | {step.images.length} images
                        </div>
                      ) : (
                        <div>⚠️ Unknown step data</div>
                      )}
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                      {isReordering && (
                        <>
                          <button onClick={() => moveStep(index, -1)}>⬆️</button>
                          <button onClick={() => moveStep(index, 1)}>⬇️</button>
                        </>
                      )}
                      <button onClick={() => handleEditStep(step)}>✏️</button>
                      <button
                        onClick={() => deleteStep(step.id)}
                        style={{ background: "transparent", color: "red", border: "none" }}
                      >
                        🗑️
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {/* === Add/Edit Step Section === */}
      {tripId && (
        <div style={{ marginTop: 30, border: "1px solid #ddd", padding: 10, borderRadius: 8 }}>
          <h2>{editingStepId ? "Edit Step" : "Add a Step"}</h2>

          {/* Step Type Selector */}
          <select
            name="actionType"
            value={stepData.actionType}
            onChange={(e) => handleInputChange(e, setStepData)}
            style={{ marginBottom: 10 }}
          >
            <option value="">-- Select Step Type --</option>
            <option value="pan">Pan</option>
            <option value="zoom">Zoom</option>
            <option value="transport">Change Transport</option>
            <option value="animation">Animation</option>
            <option value="show_images">Show Images</option>
          </select>

          {/* Render fields based on action type */}
          <div style={{ marginBottom: 10, display: "flex", flexDirection: "column", gap: "5px" }}>
            {stepData.actionType === "pan" && (
              <>
                <input type="number" name="lat" placeholder="Latitude" value={stepData.lat} onChange={(e) => handleInputChange(e, setStepData)} />
                <input type="number" name="lng" placeholder="Longitude" value={stepData.lng} onChange={(e) => handleInputChange(e, setStepData)} />
                <input type="number" name="zoom_lvl" placeholder="Zoom Level" value={stepData.zoom_lvl} onChange={(e) => handleInputChange(e, setStepData)} />
              </>
            )}

            {stepData.actionType === "zoom" && (
              <input type="number" name="zoom_lvl" placeholder="Zoom Level" value={stepData.zoom_lvl} onChange={(e) => handleInputChange(e, setStepData)} />
            )}

            {stepData.actionType === "transport" && (
              <input type="text" name="newTransport" placeholder="New Transport" value={stepData.newTransport} onChange={(e) => handleInputChange(e, setStepData)} />
            )}

            {stepData.actionType === "animation" && (
              <input type="text" name="directionsFilename" placeholder="Directions Filename" value={stepData.directionsFilename} onChange={(e) => handleInputChange(e, setStepData)} />
            )}

            {stepData.actionType === "show_images" && (
              <>
                <input type="text" name="galleryName" placeholder="Gallery Name" value={stepData.galleryName} onChange={(e) => handleInputChange(e, setStepData)} />
                <ImageDropZone />
              </>
            )}
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <button onClick={addOrUpdateStep}>
              {editingStepId ? "💾 Save Step" : "➕ Add Step"}
            </button>

            {/* Show Cancel button when editing */}
            {editingStepId && (
              <button
                onClick={() => {
                  setEditingStepId(null);
                  setStepData(getEmptyStep());
                }}
                style={{ background: "#eee", color: "#333" }}
              >
                ❌ Cancel Edit
              </button>
            )}
          </div>
        </div>
      )}
      <button
        onClick={() => history.push('/admin/transportation')}
        style={{
          display: 'flex',
          marginTop: '20px',
          alignItems: 'center',
          gap: '10px',
          padding: '10px 20px',
          backgroundColor: '#2196F3',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          // transform: 'rotate(180deg)'
        }}
      >
        <span
          style={{
            fontSize: '24px',
            display: 'inline-block',
            transform: 'scaleX(-1)', // mirror horizontally
          }}
        >
          🚗
        </span>
        Manage Transportation
      </button>
    </div>
  );
}