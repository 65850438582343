import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { apiFetch } from "../utils/api";

export default function TransportationAdminPage() {
  const clientId = useSelector((state) => state.clientId);

  const [transportations, setTransportations] = useState([]);
  const [newTransport, setNewTransport] = useState({ name: "", file: null });
  const [dragOver, setDragOver] = useState(false);

  // ✅ Wrap in useCallback to avoid infinite re-renders
  const fetchTransportations = useCallback(async () => {
    if (!clientId) return; // Make sure clientId exists before fetching!

    try {
      const response = await apiFetch(`https://hawkon.eu/api/admin/transportations?clientId=${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      // ✅ Optional safety check: data should be array
      if (Array.isArray(data)) {
        setTransportations(data);
      } else {
        console.warn("⚠️ Unexpected response format:", data);
        setTransportations([]);
      }

    } catch (error) {
      console.error("❌ Error fetching transportations:", error);
    }
  }, [clientId]);

  useEffect(() => {
    fetchTransportations();
  }, [fetchTransportations]);


  const handleFileDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const file = e.dataTransfer.files[0];

    if (!file || file.type !== "image/png") {
      alert("Only PNG images are allowed!");
      return;
    }

    const fileNameWithoutExtension = file.name.replace(/\.png$/i, "");

    setNewTransport({
      name: fileNameWithoutExtension,
      file: file
    });
  };

  const handleAddTransportation = async () => {
    if (!newTransport.name || !newTransport.file) {
      alert("Please provide both a name and an image!");
      return;
    }

    const formData = new FormData();
    formData.append("name", newTransport.name);
    formData.append("image", newTransport.file);
    formData.append("clientId", clientId);

    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/addTransportation", {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        setNewTransport({ name: "", file: null });
        fetchTransportations(); // refresh list
      } else {
        alert("❌ Failed to add transportation.");
      }
    } catch (error) {
      console.error("❌ Error adding transportation:", error);
    }
  };

  const handleFlipTransportation = async (transportId, imageUrl, transportName) => {
    if (!window.confirm("Flip this transportation image?")) return;

    try {
      const response = await apiFetch("https://hawkon.eu/api/admin/flipTransportationImage", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ transportId, imageUrl, transportName, clientId }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        console.log("✅ Image flipped successfully!");

        // 🟢 Update state directly!
        setTransportations((prev) =>
          prev.map((t) =>
            t.id === transportId
              ? { ...t, image_url: `${data.imageUrl}?cacheBust=${Date.now()}` } // bust cache
              : t
          )
        );
      } else {
        alert("❌ Failed to flip transportation image.");
      }
    } catch (error) {
      console.error("❌ Error flipping transportation:", error);
    }
  };

  const handleDeleteTransportation = async (id) => {
    if (!window.confirm("Delete this transportation?")) return;

    try {
      const response = await apiFetch(`https://hawkon.eu/api/admin/deleteTransportation/${id}?clientId=${clientId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        fetchTransportations();
      } else {
        alert("❌ Failed to delete transportation.");
      }
    } catch (error) {
      console.error("❌ Error deleting transportation:", error);
    }
  };

  return (
    <div style={{ padding: "30px", maxWidth: "800px", margin: "0 auto" }}>
      <h1 style={{ marginBottom: "20px" }}>🚗 Transportation Admin</h1>

      {/* Add new transportation */}
      <div style={{
        marginBottom: "30px",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        background: "#f9f9f9"
      }}
        onDragOver={(e) => { e.preventDefault(); setDragOver(true); }}
        onDragLeave={() => setDragOver(false)}
        onDrop={handleFileDrop}
      >
        <h2>Add New Transportation</h2>

        <div style={{
          marginBottom: "10px",
          border: "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          background: dragOver ? "#e0f7fa" : "#f9f9f9",
          cursor: "pointer"
        }}>
          {newTransport.file
            ? `✅ Selected file: ${newTransport.file.name}`
            : "Drag & drop PNG file here"}
        </div>

        <input
          type="text"
          placeholder="Name"
          value={newTransport.name}
          onChange={(e) => setNewTransport({ ...newTransport, name: e.target.value })}
          style={{ marginRight: "10px", padding: "8px", width: "300px" }}
        />

        <button
          onClick={handleAddTransportation}
          style={{
            padding: "10px 20px",
            background: "#4CAF50",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          ➕ Add
        </button>
      </div>

      {/* Transportations list */}
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {transportations && Array.isArray(transportations) && transportations.map((transport) => (
          <li
            key={transport.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "5px 15px",
              marginBottom: "15px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              minHeight: "100px" // ensures consistent row height
            }}
          >
            <img
              src={`${transport.image_url}?cacheBust=${Date.now()}`}
              alt={transport.name}
              style={{
                width: "80px",
                height: "80px", // fixed height for consistency
                objectFit: "contain",
                marginRight: "20px"
              }}
            />

            <div style={{ flex: 1 }}>
              <h3 style={{ margin: 0 }}>{transport.name}</h3>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={() =>
                  handleFlipTransportation(transport.id, transport.image_url, transport.name)
                }
                style={{
                  padding: "8px 12px",
                  background: "transparent",
                  color: "#FF9800", // orange tone for flip
                  border: "1px solid #FF9800",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px"
                }}
              >
                🔄 Flip
              </button>

              <button
                onClick={() => handleDeleteTransportation(transport.id)}
                style={{
                  padding: "8px 12px",
                  background: "transparent",
                  color: "red",
                  border: "1px solid red",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px"
                }}
              >
                🗑️ Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}