import React from 'react';

const StopMarker = ({ 
  index, 
  mediaCount, 
  expandedRows, 
  toggleRowExpansion, 
  handleDotClick, 
  assignSelectedMediaToStop, 
  currentStep,
  mediaGroups,
  countMediaTypes,
  hoverTimeout,
  setHoverTimeout,
  editingIndex,
  removeMediaFromGroup,
  handleHoverStart,
  handleHoverEnd,
  videoRefs,
  hoveredThumb,
  selectedMedia,
  showMediaIndicators = true,
  stopIndex
}) => {
  const mediaTypes = countMediaTypes(mediaGroups[index] || []);
  const isEditing = editingIndex === index;
  
  return (
    <div style={{ 
      position: "relative", 
      transform: "translate(-50%, -80%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 3
    }}>
      {/* Custom marker */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (currentStep === "selectNextDestination") {
            handleDotClick(index);
          } else {
            assignSelectedMediaToStop(index);
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (currentStep === "selectNextDestination") {
            handleDotClick(index);
          } else {
            assignSelectedMediaToStop(index);
          }
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        title={`${currentStep === "selectNextDestination" ? "Edit Stop" : "Assign selected media to Stop"} ${index + 1}`}
        style={{
          position: "relative",
          width: "24px",
          height: "32px",
          cursor: isEditing ? "default" : "pointer",
          zIndex: 2,
          opacity: isEditing ? 0.7 : 1,
          touchAction: "none",
          userSelect: "none",
          WebkitUserSelect: "none",
          WebkitTouchCallout: "none",
          transform: "rotate(12.5deg)"
        }}
      >
        {/* Cone */}
        <div style={{
          position: "absolute",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "32px solid #4A90E2",
          filter: "drop-shadow(0 2px 2px rgba(0,0,0,0.2))"
        }} />
        {/* Circle */}
        <div style={{
          position: "absolute",
          top: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "20px",
          backgroundColor: "#4A90E2",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
        }}>
          {/* White inner circle with number */}
          <div style={{
            width: "14px",
            height: "14px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "10px",
            fontWeight: "bold",
            color: "#4A90E2"
          }}>
            {stopIndex + 1}
          </div>
        </div>
      </div>

      {/* Media indicators - only show if showMediaIndicators is true */}
      {showMediaIndicators && mediaGroups[index]?.length > 0 && (
        <div 
          onClick={(e) => {
            e.stopPropagation();
            toggleRowExpansion(index);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleRowExpansion(index);
          }}
          onTouchEnd={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ 
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "2px",
            backgroundColor: isEditing ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.7)",
            padding: "4px 8px",
            borderRadius: "8px",
            display: "flex",
            gap: "6px",
            fontSize: "12px",
            color: "white",
            whiteSpace: "nowrap",
            zIndex: 2,
            cursor: "pointer",
            transition: "background-color 0.2s ease",
            userSelect: "none",
            WebkitUserSelect: "none",
            WebkitTouchCallout: "none"
          }}
        >
          {mediaTypes.images > 0 && <span>📸 {mediaTypes.images}</span>}
          {mediaTypes.videos > 0 && <span>🎥 {mediaTypes.videos}</span>}
          <span style={{ marginLeft: "4px" }}>{expandedRows[index] ? "▲" : "▼"}</span>
        </div>
      )}

      {/* Media grid - only show if showMediaIndicators is true */}
      {showMediaIndicators && expandedRows[index] && mediaGroups[index]?.length > 0 && (
        <div style={{
          position: "absolute",
          top: "100%",
          left: mediaGroups[index].length === 1 ? "calc(50% + 32px)" : "calc(50% + 25px)",
          transform: "translateX(-50%)",
          marginTop: "12px",
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "8px",
          maxWidth: "500px",
          padding: "12px",
          zIndex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box"
        }}>
          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "8px",
            width: "fit-content",
            margin: "0 auto"
          }}>
            {mediaGroups[index].map((media, mediaIndex) => (
              <div
                key={mediaIndex}
                onClick={(e) => {
                  e.stopPropagation();
                  if (hoverTimeout) {
                    clearTimeout(hoverTimeout);
                    setHoverTimeout(null);
                  }
                  if (currentStep !== "selectNextDestination" || editingIndex === null) {
                    removeMediaFromGroup(index, media);
                  }
                }}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  handleHoverStart(media, mediaIndex, true, index);
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  handleHoverEnd();
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleHoverStart(media, mediaIndex, true, index);
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleHoverEnd();
                }}
                style={{
                  width: "60px",
                  height: "60px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: currentStep === "selectNextDestination" && editingIndex !== null ? "default" : "pointer",
                  touchAction: "none",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none",
                  margin: "0 auto"
                }}
              >
                {media.type?.startsWith("video/") ? (
                  <video
                    ref={(el) => {
                      videoRefs.current[`${index}-${mediaIndex}`] = el;
                    }}
                    src={media.isBackendMedia ? media.url : URL.createObjectURL(media.file)}
                    autoPlay
                    muted
                    playsInline
                    onLoadedData={() => {
                      const ref = videoRefs.current[`${index}-${mediaIndex}`];
                      if (ref) {
                        ref.currentTime = 0;
                        ref.pause();
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "rgba(128, 128, 128, 0.3)",
                      display: "block"
                    }}
                  />
                ) : (
                  <img
                    src={media.isBackendMedia ? media.url : URL.createObjectURL(media.file)}
                    alt={`Media ${mediaIndex}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "rgba(128, 128, 128, 0.3)",
                      display: "block"
                    }}
                  />
                )}
                <div style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0,0,0,0.5)",
                  clipPath: hoveredThumb === `assigned-${index}-${mediaIndex}`
                    ? "circle(80% at center)"
                    : "circle(0% at center)",
                  transition: "clip-path 1s ease-in-out",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }} />
                {selectedMedia.includes(media) && (
                  <div style={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#4CAF50",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontSize: "12px",
                    zIndex: 2
                  }}>
                    ✓
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StopMarker; 