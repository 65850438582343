type State = {
  spaceCount: int
};

// Borneo is at -4, Austrlia is at 0, Thailand at 111,
const initialState: State = 111;

export default function spaceCountReducer(
  state: State = initialState,
  action: {
    type: string,
    spaceCount: int
  }
): State {
  switch (action.type) {
    case 'SET_SPACE_COUNT':
      return action.spaceCount;

    case 'INCREMENT_SPACE_COUNT':
      return state + 1;
    default:
      return state;
  }
}
