type State = Boolean;

const initialState: State = false;

export default function autoPlayReducer(
  state: State = initialState,
  action: {
    type: string
  }
): State {
  switch (action.type) {
    case 'TOGGLE_AUTO_PLAY':
      return !state;
    default:
      return state;
  }
}
