import React, { useState } from "react";
import * as exifr from "exifr";

export default function ImageDropZone({ onFilenamesReady }) {
  const [fileNames, setFileNames] = useState("");
  const [gpsData, setGpsData] = useState([]);

  const handleFileDrop = async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (!files.length) return;

    // 1. Extract just the names
    const names = files.map((file) => file.name);
    setFileNames(names.join(","));

    // 2. For each file, extract GPS data
    const gpsResults = [];

    for (const file of files) {
      try {
        const metadata = await exifr.parse(file, { gps: true });
        console.log(`📸 File: ${file.name}`);
        console.log("Metadata:", metadata);

        if (metadata && metadata.latitude && metadata.longitude) {
          gpsResults.push({
            file: file.name,
            lat: metadata.latitude,
            lng: metadata.longitude,
          });
        } else {
          gpsResults.push({
            file: file.name,
            lat: null,
            lng: null,
          });
        }
      } catch (err) {
        console.error(`❌ Failed to read EXIF for ${file.name}:`, err);
      }
    }

    setGpsData(gpsResults);

    // Optionally: Pass the comma-separated filenames to parent
    if (onFilenamesReady) onFilenamesReady(names.join(","));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        style={{
          border: "2px dashed #aaa",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
          color: "#777",
        }}
      >
        📂 Drag and drop files here
      </div>

      {fileNames && (
        <div style={{ marginTop: 10 }}>
          <strong>Comma-separated filenames:</strong>
          <textarea
            style={{ width: "100%", height: 50, marginTop: 10 }}
            value={fileNames}
            readOnly
          />
        </div>
      )}

      {gpsData.length > 0 && (
        <div style={{ marginTop: 10 }}>
          <strong>Extracted GPS:</strong>
          <ul>
            {gpsData.map(({ file, lat, lng }) => (
              <li key={file}>
                {file}: {lat && lng ? `Lat: ${lat}, Lng: ${lng}` : "No GPS data"}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}