import { combineReducers } from 'redux';

import spaceCount from './spaceCount';
import clientId from './clientId';
import trips from './trips';
import transportations from './transportations';
import isAuthenticated from './isAuthenticated';
import vanLocation from './vanLocation';
import autoPlay from './autoPlay';
import images from './images';

const rootReducer = combineReducers({
  spaceCount,
  clientId,
  images,
  isAuthenticated,
  trips,
  vanLocation,
  autoPlay,
  transportations
});

// export type Reducers = typeof rootReducer;

export default rootReducer;
