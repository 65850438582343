type State = {
  lat: float,
  lng: float
};

const initialState: State = {
  lat: -31.9390252,
  lng: 115.7989048
};

export default function vanLocationReducer(
  state: State = initialState,
  action: {
    type: string,
    lat: float,
    lng: float
  }
): State {
  switch (action.type) {
    case 'SET_VAN_LOCATION':
      return {
        ...state,
        lat: action.lat,
        lng: action.lng
      };

    default:
      return state;
  }
}
