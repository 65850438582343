import polyline from '@mapbox/polyline';

const decodePolyline = (encoded) => {
  if (!encoded) return [];
  return polyline.decode(encoded).map(([lat, lng]) => ({ lat, lng }));
};


// Calculate center and zoom between two points
export function getBoundsOfTripFromHomeAndSteps(homeLat, homeLng, steps, screenWidth, screenHeight) {
  // 1️⃣ Gather all lat/lng points (home + steps)
  // ✅ Early check to prevent bad inputs
  if (homeLat === undefined || homeLng === undefined) {
    console.warn("❌ homeLat or homeLng is undefined");
    return { center: { lat: 0, lng: 0 }, zoom: 3 };
  }

  // 1️⃣ Gather all lat/lng points (home + steps)
  const points = [
    { lat: parseFloat(homeLat), lng: parseFloat(homeLng) },
    ...steps
      .filter(s => !isNaN(parseFloat(s.lat)) && !isNaN(parseFloat(s.lng)))
      .map(s => ({ lat: parseFloat(s.lat), lng: parseFloat(s.lng) }))
  ];

  if (points.length === 0) {
    console.warn("❌ No valid points to calculate bounds");
    return { center: { lat: 0, lng: 0 }, zoom: 3 };
  }

  // 2️⃣ Calculate bounds
  let minLat = points[0].lat;
  let maxLat = points[0].lat;
  let minLng = points[0].lng;
  let maxLng = points[0].lng;

  points.forEach(({ lat, lng }) => {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  const bounds = {
    ne: { lat: maxLat, lng: maxLng },
    sw: { lat: minLat, lng: minLng }
  };

  // 3️⃣ Calculate center point
  const center = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2
  };

  // 4️⃣ Calculate zoom based on bounds + screen size
  const zoom = getZoomForBounds(bounds, screenWidth, screenHeight);

  return { center, zoom };
}

function getZoomForBounds(bounds, mapWidthPx, mapHeightPx) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = (lat) => {
    const sin = Math.sin(lat * Math.PI / 180);
    return Math.log((1 + sin) / (1 - sin)) / 2;
  };

  const zoomFn = (mapPx, worldPx, fraction) => {
    if (fraction === 0) return ZOOM_MAX; // Prevent division by zero
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  };

  const minFraction = 0.000001;

  const latFraction = Math.max((latRad(bounds.ne.lat) - latRad(bounds.sw.lat)) / Math.PI, minFraction);
  const lngDiff = bounds.ne.lng - bounds.sw.lng;
  const lngFraction = Math.max(((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360, minFraction);

  const latZoom = zoomFn(mapHeightPx, WORLD_DIM.height, latFraction);
  const lngZoom = zoomFn(mapWidthPx, WORLD_DIM.width, lngFraction);
  const zoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
  return zoom + 0; // ✅ Subtract for padding, adjust if needed!
}

// ✅ Calculate bounds, center, and zoom between two points
export function getBoundsBetweenPoints(start, end, screenWidth, screenHeight) {
  // 1️⃣ Validate inputs
  if (!start || !end || isNaN(start.lat) || isNaN(start.lng) || isNaN(end.lat) || isNaN(end.lng)) {
    console.warn("❌ Invalid start or end coordinates", { start, end });
    return { center: { lat: 0, lng: 0 }, zoom: 3 };
  }

  // 2️⃣ Calculate min/max lat/lng
  const minLat = Math.min(start.lat, end.lat);
  const maxLat = Math.max(start.lat, end.lat);
  const minLng = Math.min(start.lng, end.lng);
  const maxLng = Math.max(start.lng, end.lng);

  const bounds = {
    ne: { lat: maxLat, lng: maxLng },
    sw: { lat: minLat, lng: minLng }
  };

  // 3️⃣ Center point between start and end
  const center = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2
  };

  // 4️⃣ Calculate zoom
  const zoom = getZoomForBounds(bounds, screenWidth, screenHeight);

  return { center, zoom };
}

export function getBoundsForSteps(steps, screenWidth, screenHeight) {
  if (!steps || !steps.length) {
    console.warn("❌ No steps provided to calculate bounds");
    return { center: { lat: 0, lng: 0 }, zoom: 3 };
  }

  // 1️⃣ Filter valid points
  const validPoints = steps
    .filter(s => !isNaN(parseFloat(s.lat)) && !isNaN(parseFloat(s.lng)))
    .map(s => ({
      lat: parseFloat(s.lat),
      lng: parseFloat(s.lng),
    }));

  if (validPoints.length === 0) {
    console.warn("❌ No valid points found in steps");
    return { center: { lat: 0, lng: 0 }, zoom: 3 };
  }

  // 2️⃣ Calculate min/max lat/lng
  let minLat = validPoints[0].lat;
  let maxLat = validPoints[0].lat;
  let minLng = validPoints[0].lng;
  let maxLng = validPoints[0].lng;

  validPoints.forEach(({ lat, lng }) => {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  const bounds = {
    ne: { lat: maxLat, lng: maxLng },
    sw: { lat: minLat, lng: minLng },
  };

  const center = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2,
  };

  // 3️⃣ Calculate zoom
  const zoom = getZoomForBounds(bounds, screenWidth, screenHeight);

  return { center, zoom };
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function setZoomAndWait(map, targetZoom) {
  if (!map || !map.getZoom) {
    console.error("🛑 Invalid map instance passed to setZoomAndWait");
    return;
  }

  const currentZoom = map.getZoom();

  if (currentZoom === undefined || currentZoom === null) {
    console.warn("⚠️ Could not get current zoom level.");
    return;
  }

  const zoomDiff = Math.abs(targetZoom - currentZoom);

  // 🕒 Calculate delay: 300ms per zoom level diff (tweak as needed)
  const delayPerStep = 300; // ms
  const totalDelay = zoomDiff * delayPerStep;

  console.log(`🔍 Changing zoom from ${currentZoom} to ${targetZoom}`);
  console.log(`⏳ Waiting for ${totalDelay}ms based on zoom diff ${zoomDiff}`);

  map.setZoom(targetZoom);

  await sleep(totalDelay);

  console.log("✅ Zoom set and waited.");
}


export const fetchNearestPoint = async (lat, lng, clientId) => {
  try {
    const response = await fetch(`https://hawkon.eu/api/nearestRoad?lat=${lat}&lng=${lng}&clientId=${clientId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (!data || !data.lat || !data.lng) {
      console.warn("⚠️ Could not snap to nearest routable point. Using original location.");
      return null;
    }

    return data;
  } catch (error) {
    console.error("❌ Error fetching nearest point:", error);
    return null;
  }
};

export const fetchRoute = async (start, end, clientId) => {
  try {
    // 🔥 Validate inputs: start and end must be objects with valid lat/lng
    const isValidCoord = (point) =>
      point &&
      typeof point.lat === "number" &&
      typeof point.lng === "number" &&
      !isNaN(point.lat) &&
      !isNaN(point.lng);

    if (!isValidCoord(start) || !isValidCoord(end)) {
      console.warn("⚠️ Invalid start or end coordinates", { start, end });
      return null;
    }

    // (Optional) Snap to nearest routable points if you need to
    const nearestStart = start; // await fetchNearestPoint(start.lat, start.lng, clientId);
    const nearestEnd = end;     // await fetchNearestPoint(end.lat, end.lng, clientId);

    // Double-check snapped points
    if (!isValidCoord(nearestStart) || !isValidCoord(nearestEnd)) {
      console.warn("⚠️ Could not get valid nearest road points", { nearestStart, nearestEnd });
      return null;
    }

    // 🔥 Fetch route
    const response = await fetch(
      `https://hawkon.eu/api/route?startLat=${nearestStart.lat}&startLng=${nearestStart.lng}&endLat=${nearestEnd.lat}&endLng=${nearestEnd.lng}&clientId=${clientId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      console.error(`❌ Route fetch failed with status ${response.status}`);
      return null;
    }

    const data = await response.json();

    console.log("📦 Route API data:", data);

    // 🔎 Validate route data
    if (
      !data ||
      !Array.isArray(data.routes) ||
      data.routes.length === 0 ||
      !data.routes[0].geometry
    ) {
      console.warn("⚠️ No valid route found in API response.");
      return null;
    }

    // 🔧 Decode polyline geometry (GeoJSON is better but depends on API)
    const encodedPolyline = data.routes[0].geometry;

    // Decode polyline if necessary
    const coords = decodePolyline(encodedPolyline); // You need a polyline decoder
    if (!coords || coords.length === 0) {
      console.warn("⚠️ Polyline decoding failed or returned empty coordinates.");
      return null;
    }

    return coords; // [{ lat, lng }, ...]
  } catch (error) {
    console.error("❌ Error fetching route:", error);
    return null;
  }
};


